import "./index.css";

const ValidatedInput = (props) => {
  const { error, ...rest } = props;
  return (
    <div className="validated-input">
      <input {...rest} autoComplete="off" />
      {error && <span className="errorMsg">{error}</span>}
    </div>
  );
};
export default ValidatedInput;
