import { useEffect, useState } from "react";
import InfoText from "components/InfoText";
import PropertyGrid from "components/PropertyGrid";

const fontName = [
    "arial", 
    "brush script mt", 
    "calibri",
    "courier",
    "garamond",
    "helvetica",
    "segoe ui",
    "tahoma",
    "times new roman",
    "verdana"];
    
const fontSize = [8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32];

const lookups = {
    fontName,
    fontSize
};

function PbaasPropertyGrid({element, onChange}) {
    const [propertyList, setPropertyList] = useState([]);

    useEffect(() => {
        if (element) {
            const propList = Object.keys(element)
                .filter(name => includeProperty(element, name))
                .map(name => ({
                    name,
                    value: element[name],
                    type: getTypeFromName(name)
            }));

            setPropertyList(propList);
        }

    }, [element])

    
    return (
        propertyList?.length > 0 ? 
            <PropertyGrid propertyList={propertyList} lookups={lookups} onChange={onChange}/>
            :
            <InfoText>Select an element to edit its properties.</InfoText>
    );
    
}

function getTypeFromName(name) {
    if (name.indexOf("Color") > -1) return "color";
    return name;
}

function includeProperty(element, propertyName) {
    const excludeFields = ["key", "text", "displayText"]

    if (excludeFields.includes(propertyName)) return false;
    if (typeof element[propertyName] === "object") return false;  
    return true;
}

export default PbaasPropertyGrid;