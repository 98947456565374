import { Link } from "react-router-dom";
import "./index.css";

function LinkButton({to, onClick, type, disabled, children}) {

    if (onClick || disabled) to = "#";
    type = type || "blue";
    
    const handleClick = (e) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    }

    function getClassName() {
        const classNames = [type];
        if (disabled) classNames.push("disabled");
        return classNames.join(" ");
    };

    return (
        <span className="link-button">
            <Link to={to} onClick={handleClick} className={getClassName()}>{children}</Link>   
        </span>
    )
}

export default LinkButton;