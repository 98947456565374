import "./index.css";
import TextDesigner from "screens/PbaasAppUI/components/TextDesigner";
import TextButtonDesigner from "screens/PbaasAppUI/components/TextButtonDesigner";

function PbaasDialogDesigner({dialog, selectedKey, onSelectElement}) {
    return (
        <div className="dialog-designer">
            <TextDesigner 
                className="title"
                element={dialog.title} 
                selectedKey={selectedKey} 
                onSelectElement={onSelectElement}/>

            <TextDesigner 
                className="body"
                element={dialog.body} 
                selectedKey={selectedKey} 
                onSelectElement={onSelectElement}/>
        
            <div className="button-area">
                <TextButtonDesigner
                    className="button-cancel"
                    element={dialog.buttonCancel} 
                    selectedKey={selectedKey} 
                    onSelectElement={onSelectElement}/>
        
                <TextButtonDesigner
                    className="button-ok"
                    element={dialog.buttonOk} 
                    selectedKey={selectedKey} 
                    onSelectElement={onSelectElement}/>
            </div>
        </div>
    );
}

export default PbaasDialogDesigner