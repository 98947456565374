import moment from "moment";

const platform = {
  android: "Android",
  ios: "iOS",
  unknown: "Unknown",
};

export const hourlyStatisticMetrics = {
  sessionStarted: "sessionsStarted",
  pinBlockGenerated: "pinBlockGenerated",
  businessError: "businessErrors",
  systemError: "systemErrors",
};

// **************************************************************************************************
// Base function for performing sum calculation on the hourly statistics data for a given metric/platform
// **************************************************************************************************

// Performs a sum calculation on the hourly statistics for a given metric (e.g. `sessionsStarted`)
export function getTotalHourlyStatisticMetricByKey(data, key, platformFilter = null) {
  if (!data) return;
  let count = 0;

  data?.map((d) => {
    let android = d.androidMetrics?.[key];
    let ios = d.iosMetrics?.[key];
    let unknown = d.unknownPlatformMetrics?.[key];

    // Zero other counts before sum if requested count is for a particular platform
    if (platformFilter === platform.android) ios = unknown = 0;
    if (platformFilter === platform.ios) android = unknown = 0;
    if (platformFilter === platform.unknown) android = ios = 0;

    count += parseNum(android) + parseNum(ios) + parseNum(unknown);
  });
  return count;
}

// **************************************************************************************************
// Overview of data - Combines metrics for all platforms to provide sum for each metric
// **************************************************************************************************

// Builds the object for the overview of hourly statistics
function createTotalHourlyStatisticMetrics(relevantData, key) {
  return {
    key,
    totalSessions: getTotalHourlyStatisticMetricByKey(relevantData, hourlyStatisticMetrics.sessionStarted),
    totalPinBlocks: getTotalHourlyStatisticMetricByKey(relevantData, hourlyStatisticMetrics.pinBlockGenerated),
    totalBusinessErrors: getTotalHourlyStatisticMetricByKey(relevantData, hourlyStatisticMetrics.businessError),
    totalSystemErrors: getTotalHourlyStatisticMetricByKey(relevantData, hourlyStatisticMetrics.systemError),
  };
}

// Get the hourly statistics overview for last X hours. Data interval is 1 hour.
export function getTotalHourlyStatisticMetricsForLastXHours(data, hours) {
  if (!data) return;
  let result = [];

  for (let i = 1; i <= hours; i++) {
    let momentHour = moment.utc().subtract(hours - i, "hours");

    let relevantData = data.filter((d) => areMomentsSameHour(moment(d.hourStartUtc), momentHour));
    result.push(createTotalHourlyStatisticMetrics(relevantData, momentHour.local().format("HH:00")));
  }
  return result;
}

// Get the hourly statistics overview for last x days. Data interval is 1 day.
export function getTotalHourlyStatisticMetricsForLastXDays(data, days) {
  if (!data) return;

  let result = [];

  for (let i = 1; i <= days; i++) {
    let momentDay = moment()
      .utc()
      .subtract(days - i, "days");

    let relevantData = data.filter((d) => areMomentsSameDay(moment(d.hourStartUtc), momentDay));
    result.push(createTotalHourlyStatisticMetrics(relevantData, momentDay.format("DD MMM")));
  }
  return result;
}

// **************************************************************************************************
// Count of Pin Blocks by Platform
// **************************************************************************************************

// Builds the object for pinblocks generated by platform
function createPinBlocksByPlatformMetric(relevantData, key) {
  return {
    key,
    totalPinBlocksAndroid: getTotalHourlyStatisticMetricByKey(
      relevantData,
      hourlyStatisticMetrics.pinBlockGenerated,
      platform.android
    ),
    totalPinBlocksIos: getTotalHourlyStatisticMetricByKey(relevantData, hourlyStatisticMetrics.pinBlockGenerated, platform.ios),
  };
}

// Get the pin blocks generated by platform for last x hours from hourly statistics. Data interval is 1 hour.
export function getPinBlocksByPlatformForLastXHours(data, hours) {
  if (!data) return;
  let result = [];

  for (let i = 1; i <= hours; i++) {
    let momentHour = moment.utc().subtract(hours - i, "hours");

    let relevantData = data.filter((d) => areMomentsSameHour(moment(d.hourStartUtc), momentHour));
    result.push(createPinBlocksByPlatformMetric(relevantData, momentHour.local().format("HH:00")));
  }
  return result;
}

// Get the pin blocks generated by platform for last x days from hourly statistics. Data interval is 1 day.
export function getPinBlocksByPlatformForLastXDays(data, days) {
  if (!data) return;

  let result = [];

  for (let i = 1; i <= days; i++) {
    let momentDay = moment()
      .utc()
      .subtract(days - i, "days");

    let relevantData = data.filter((d) => areMomentsSameDay(moment(d.hourStartUtc), momentDay));
    result.push(createPinBlocksByPlatformMetric(relevantData, momentDay.format("DD MMM")));
  }

  return result;
}

// **************************************************************************************************
// Count of sessions by platform.
// **************************************************************************************************

// Get the count of sessions by platforms
export function getSessionCountByPlatform(data) {
  if (!data) return;
  return [
    { name: "Android", value: getTotalHourlyStatisticMetricByKey(data, hourlyStatisticMetrics.sessionStarted, platform.android) },
    { name: "iOS", value: getTotalHourlyStatisticMetricByKey(data, hourlyStatisticMetrics.sessionStarted, platform.ios) },
  ];
}

// **************************************************************************************************
// Utility functions
// **************************************************************************************************

// Attempt to parse a number. Returns 0 if NaN.
function parseNum(num) {
  if (isNaN(num)) return 0;
  return num;
}

// Check if 2 moments are the same UTC hour.
function areMomentsSameHour(m1, m2) {
  let datetimeFormat = "DDMMYYYY HH";
  return m1.utc().format(datetimeFormat) === m2.utc().format(datetimeFormat);
}

// Check if 2 moments are the same UTC day.
function areMomentsSameDay(m1, m2) {
  return m1.utc().isSame(m2.utc(), "day");
}
