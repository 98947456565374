import Container from "components/Container";
import LinkButton from "components/LinkButton";
import "css/table.css";
import { useUrl } from "lib/site";
import React from "react";
import { Link } from "react-router-dom";

const OrganisationsList = ({ organisations }) => {
  const getUrl = useUrl();

  for (var org of organisations || []) {
    const params = { organisationId: org.id };
    org.overviewUrl = getUrl("OrganisationOverview", params);
    org.editUrl = getUrl("OrganisationEdit", params);
  }

  return (
    <Container width={"700px"}>
      <table className="ampTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Manage Organisation</th>
          </tr>
        </thead>
        <tbody>
          {organisations?.map((org) => (
            <tr key={org.id}>
              <td>
                <Link to={org.overviewUrl}>{org.name}</Link>
              </td>
              <td>
                <LinkButton type="blue" to={org.editUrl}>
                  Manage
                </LinkButton>
              </td>
            </tr>
          ))}
          {(!organisations || !organisations.length) && (
            <tr>
              <td className="text-start" colSpan={3}>
                <span>No organisations found.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Container>
  );
};
export default OrganisationsList;
