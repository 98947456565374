import { useParams } from "react-router-dom";
import Users from "screens/components/Users";

function OrganisatonUsers() {
  const { organisationId } = useParams();
  return (
    <Users
      organisationId={organisationId}
      createUserScreen="OrganisationUserCreate"
      editUserScreen="OrganisationUserEdit"
      displayOrganisationsFilter={false}
    />
  );
}

export default OrganisatonUsers;
