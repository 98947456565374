import Container from "components/Container";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import ValidatedInput from "components/ValidatedInput";
import usePbaasApp from "lib/pbaas/usePbaasApp";
import { useUrl } from "lib/site";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

function PbaasAppCreate() {
  const navigate = useNavigate();
  const getUrl = useUrl();
  const cancelUrl = getUrl("PbaasApps");
  const overviewUrlTemplate = useUrl()("PbaasAppOverview", { appId: "APP_ID" });
  const [app, setApp, api, info] = usePbaasApp();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApp((mutableApp) => (mutableApp[name] = value));
  };

  useEffect(() => {
    if (app && app.appId && info.serverUpdated) {
      displayToast(`Pbaas app: ${app.name} created.`);
      const url = overviewUrlTemplate.replace("APP_ID", app.appId);
      navigate(url);
    }
  }, [app, info.serverUpdated, navigate, overviewUrlTemplate]);

  const handleSave = async () => {
    var result = await api.create();

    setApp((mutableApp) => (mutableApp.appId = result.appId));
  };

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={cancelUrl}>
            Cancel
          </LinkButton>
        </PageActions>

        <div id="create-pbaas-app" onChange={handleChange}>
          <Container heading="Create Pbaas App">
            <fieldset>
              <label className="ampFormLabel" as="legend">
                App Name
              </label>
              <ValidatedInput className="ampFormControl" name="name" type="text" value={app.name} error={info.formErrors.name} />
            </fieldset>
          </Container>
        </div>
      </>
    );
  }

  const ready = !info.isBusy;

  return <>{ready ? renderContent() : <Loader centerInline />}</>;
}

export default PbaasAppCreate;
