import SelectableElement from "../SelectableElement";
import "./index.css";

function ImageButtonDesigner({ element, selectedKey, className, onSelectElement }) {
  // quick temp hack !
  function getImagePath(imageId) {
    const basePath = "/assets/images/icons/";

    if (imageId === 2) return basePath + "enter/enter1.svg";
    if (imageId === 3) return basePath + "clear/clear1.svg";
  }

  return (
    <>
      {element && (
        <div className={"image-button " + className}>
          <SelectableElement element={element} isSelected={element.key === selectedKey} onSelectElement={onSelectElement}>
            <div className="image-container">
              <img src={getImagePath(element.imageId)}></img>
            </div>
          </SelectableElement>
        </div>
      )}
    </>
  );
}

export default ImageButtonDesigner;
