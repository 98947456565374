import Swal from "sweetalert2";
import "./SweetAlert.css";

export const alertTypes = {
  error: {
    icon: "error",
    showConfirmButton: true,
    confirmButtonText: null,
    allowOutsideClick: true,
    backdrop: true,
    allowEscapeKey: true,
    showCancelButton: false,
    customClass: {
      confirmButton: "alert-button blue",
    },
  },
  confirm: {
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: "Confirm",
    allowOutsideClick: true,
    backdrop: true,
    allowEscapeKey: true,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    customClass: {
      confirmButton: "alert-button blue",
      cancelButton: "alert-button grey",
    },
  },
  warning: {
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: null,
    allowOutsideClick: true,
    backdrop: true,
    allowEscapeKey: true,
    showCancelButton: false,
    customClass: {
      confirmButton: "alert-button blue",
    },
  },
};

export function displayAlert(title, message, alertOptions, onDone) {
  Swal.mixin({
    customClass: alertOptions.customClass,
    buttonsStyling: !alertOptions.customClass,
  })
    .fire({
      title,
      html: message,
      ...alertOptions,
    })
    .then((result) => (onDone ? onDone(result) : null));
}
export default Swal;
