import { httpMethods } from "./util";

export function queryLogs(organisationId) {
  return {
    url: `Log/organisation/${organisationId}/query`,
    options: { method: httpMethods.post },
  };
}

export function countLogs(organisationId) {
  return {
    url: `Log/organisation/${organisationId}/count`,
    options: { method: httpMethods.post },
  };
}
