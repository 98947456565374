import "./index.css";

function DynamicList({ items, onCreate, onDelete }) {
  function renderItem(item, index) {
    return (
      <div className="dynamic-list-item">
        {item}
        {
          <div>
            <button className="ampFormControl dynamic-list-btn dynamic-list-delete-btn" onClick={() => onDelete(index)}>
              Delete
            </button>
          </div>
        }
      </div>
    );
  }

  return (
    <>
      {<div className="dynamic-list">{items?.map(renderItem)}</div>}
      <div>
        <button className="ampFormControl dynamic-list-btn" id="dynamic-list-add-btn" onClick={onCreate}>
          Add
        </button>
      </div>
    </>
  );
}

export default DynamicList;
