import { toast } from "react-toastify";

export function displayToast(text, type = "success") {
  toast(text, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    type,
  });
}
