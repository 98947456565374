import CheckboxList from "components/CheckboxList";
import Container from "components/Container";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import Swal, { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import ValidatedInput from "components/ValidatedInput";
import useUser from "lib/user/useUser";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";

function UserEdit({ organisationId, returnUrl }) {
  const navigate = useNavigate();
  const { userId } = useParams();
  const isCreating = userId === undefined;
  const [user, setUser, api, info] = useUser(organisationId);
  const [permissionDescription, setPermissionDescription] = useState("");

  useEffect(() => {
    info.serverUpdated && navigate(returnUrl);
  }, [info.serverUpdated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((mutableUser) => (mutableUser[name] = value));
  };

  const handlePermissionChange = (name, checked) => {
    setUser((mutableUser) => {
      mutableUser.permissions.filter((p) => p.name === name)[0].checked = checked;
    });
  };

  const handlePermissionMouseEnter = (name) => {
    const d = user.permissions.filter((p) => p.name === name)[0].description;
    setPermissionDescription(d);
  };

  const handlePermissionMouseLeave = () => {
    setPermissionDescription("");
  };

  const handleSave = async () => {
    if (isCreating) {
      if (await api.create()) {
        displayToast(`User: ${user.displayName} created.`);
      } else {
        displayToast("An error occurred creating the user.", "error");
      }
    } else {
      if (await api.update()) {
        displayToast(`User: ${user.displayName} updated.`);
      } else {
        displayToast("An error occurred updating the user.", "error");
      }
    }
  };

  const handleDelete = async () => {
    displayAlert(
      `Delete ${user.displayName}?`,
      `Please confirm you would like to delete user: ${user.displayName}`,
      alertTypes.confirm,
      async (result) => {
        if (!result.isConfirmed) return;

        if (await api.delete()) {
          displayToast(`User: ${user.displayName} deleted.`);
        } else {
          displayToast("An error occurred deleting the user.", "error");
        }
      }
    );
  };

  useEffect(() => {
    if (isCreating && info && !info.isBusy && user && (!info.allowedDomains || !info.allowedDomains.length)) {
      displayAlert(
        "No email domains configured",
        "Please contact Support as no email domains have been configured for this organisation.",
        {
          ...alertTypes.error,
          confirmButtonText: "View all users",
          allowOutsideClick: false,
          allowEscapeKey: false,
        },
        (result) => {
          if (result.isConfirmed) navigate(returnUrl);
        }
      );
    } else {
      Swal.close();
    }
  }, [info]);

  function renderMain() {
    return (
      <div id="user-edit">
        <Container heading={isCreating ? "Create User" : "Edit User"}>
          <fieldset onChange={handleChange}>
            <div>
              <label className="ampFormLabel" as="legend">
                Email
              </label>
              <ValidatedInput
                className="ampFormControl"
                name="email"
                type="email"
                disabled={!isCreating}
                defaultValue={user.email}
                error={info.formErrors.email}
                placeholder={info.allowedDomains?.map((d) => "user@" + d).join(" or ")}
              />
            </div>
            <div>
              <label className="ampFormLabel" as="legend">
                Display Name
              </label>
              <ValidatedInput
                className="ampFormControl"
                name="displayName"
                type="text"
                defaultValue={user.displayName}
                error={info.formErrors.displayName}
              />
            </div>
          </fieldset>
        </Container>

        <Container heading="Permissions">
          <div id="permission-container">
            <div id="permission-list-container">
              <CheckboxList
                items={user.permissions}
                nameField="name"
                valueField="checked"
                onChange={handlePermissionChange}
                onMouseEnter={handlePermissionMouseEnter}
                onMouseLeave={handlePermissionMouseLeave}
              />
            </div>
            <div id="permission-description">{permissionDescription}</div>
          </div>
        </Container>
      </div>
    );
  }

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={returnUrl}>
            Cancel
          </LinkButton>
          &nbsp;&nbsp;&nbsp;
          {!isCreating && (
            <LinkButton type="red" onClick={handleDelete}>
              Delete
            </LinkButton>
          )}
        </PageActions>
        {renderMain()}
      </>
    );
  }

  const ready = user && !info.isBusy;
  return <>{ready ? renderContent() : <Loader centerInline />}</>;
}

export default UserEdit;
