import { generatePath, matchRoutes, useLocation, useMatch } from "react-router-dom";

var currentUser = null;
var _nodes = [];
var siteData = [];
var routes = [];

export async function initSite(userData) {
  currentUser = userData;
  siteData = await loadConfig("/config", "index.json");
  _nodes = selectNodes();

  // set node relationships
  iterate((node, parentNode) => {
    node.key = Math.random().toString(36).slice(2, 16) + new Date().valueOf();
    node.parent = parentNode;
    node.siblings = parentNode ? parentNode.children : siteData;
  });

  routes = _nodes.map((n) => {
    return { path: n.route };
  });
}

export function getAdminOrgId() {
  return process.env.REACT_APP_ADMIN_ORGANISATION_ID.trim();
}

export function getNodes() {
  return permissionFilter(_nodes);
}

export function getDefaultUrl() {
  const user = getCurrentUser();
  const nodes = getNodes(user);
  const route = nodes[0].route; // the first permitted node is the default
  return generatePath(route, { organisationId: user.organisationId });
}

export function useMenuItems() {
  const node = useCurrentNode();
  const params = useNodeParams(node);

  var menuNodes = node.siblings.filter((n) => n.showInMenu);
  menuNodes = permissionFilter(menuNodes);

  return menuNodes.map((n) => {
    return {
      path: generatePath(n.route, params),
      text: n.text,
      key: n.key,
      icon: n.icon,
    };
  });
}

export function useUrl() {
  const nodeParams = useNodeParams();

  return (name, additionalParams) => {
    const params = {
      ...nodeParams,
      ...additionalParams,
    };
    const node = selectNode((n) => n.screen === name);
    return generatePath(node.route, params);
  };
}

export function useBreadcrumbs() {
  const output = [];
  var node = useCurrentNode();
  const match = useMatch(node.route);
  const params = useNodeParams(node);
  const defaultUrl = getDefaultUrl();

  // is the match exact
  var isExact = match && node.route === match.pattern.path;
  if (!isExact) return [];

  // is the start node home?
  const startNodeIsHome = generatePath(node.route, params) === defaultUrl;

  output.push({
    text: node.text,
    key: node.key,
  });

  while (node.parent) {
    if (userCanAccess(node.parent)) {
      output.push({
        path: generatePath(node.parent.route, params),
        text: node.parent.text,
        key: node.parent.key,
      });
    }
    node = node.parent;
  }

  if (!startNodeIsHome) {
    output.push({
      path: defaultUrl,
      text: "Home",
      key: "home",
    });
  }

  return output.reverse();
}

export function canAccessScreen(screenName) {
  const node = selectNode((n) => n.screen === screenName);
  return userCanAccess(node);
}

function getCurrentUser() {
  return currentUser;
}

function permissionFilter(nodes) {
  const user = getCurrentUser();
  return nodes.filter((n) => userCanAccess(n, user));
}

function userCanAccess(node, user) {
  user = user || getCurrentUser();
  const userPermissionNames = user.permissions.map((p) => p.name);

  if (node.permissions.includes("None")) return true;
  return node.permissions.some((p) => userPermissionNames.includes(p));
}

function useNodeParams(node) {
  const currentNode = useCurrentNode();
  node = node || currentNode;
  const match = useMatch(node.route);
  return match?.params || {};
}

function useCurrentNode() {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  if (!matchedRoutes) return getUnmatchedNode();

  const currentRoute = matchedRoutes[0];
  return selectNode((n) => n.route === currentRoute.route.path);
}

function getUnmatchedNode() {
  return {
    route: "unmatched",
    siblings: [],
  };
}

function selectNode(predicate) {
  const nodes = selectNodes(predicate);

  if (nodes.length !== 1) {
    throw new Error(`Expected to select a single node but ${nodes.length} were selected.`);
  }

  return nodes[0];
}

function selectNodes(predicate) {
  const output = [];
  iterate((node) => {
    if (!predicate || predicate(node)) {
      output.push(node);
    }
  });
  return output;
}

function iterate(action, nodes, parentNode) {
  nodes = nodes || siteData;

  for (var node of nodes) {
    action(node, parentNode);

    if (node.children) {
      iterate(action, node.children, node);
    }
  }
}

async function loadConfig(basePath, fileName) {
  const path = `${basePath}/${fileName}`;
  const items = await getJson(path);

  for (var i of items) {
    if (i.children) {
      i.children = await loadConfig(basePath, i.children);
    }
  }

  return items;
}

async function getJson(path) {
  const response = await fetch(path);
  return await response.json();
}
