import Container from "components/Container";
import LinkButton from "components/LinkButton";
import ObjectEditor from "components/ObjectEditor";
import PageActions from "components/PageActions";
import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import ValidatedInput from "components/ValidatedInput";
import useLanguage from "lib/language/useLanguage";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";

function LanguageEdit({ defaultLocale, returnUrl }) {
  const { locale } = useParams();
  const heading = locale ? "Edit Language" : "Create Language";
  const navigate = useNavigate();
  const [language, setLanguage, api, info] = useLanguage();

  useEffect(() => {
    info.serverUpdated && navigate(returnUrl);
  }, [info.serverUpdated]);

  const handleSave = async () => {
    if (await api.update()) {
      displayToast(`Language:${locale} updated.`);
    }
  };

  const handleDelete = async () => {
    displayAlert(
      `Delete ${locale}?`,
      `Please confirm you would like to delete the language:${locale}`,
      alertTypes.confirm,
      async (result) => {
        if (!result.isConfirmed) return;
        try {
          await api.delete();
          displayToast(`Language:${locale} deleted.`);
        } catch {
          displayToast("An error occurred while deleting the language.", "error");
        }
      }
    );
  };

  const handleLocaleChange = (e) => {
    const { name, value } = e.target;
    setLanguage((mutableLanguage) => (mutableLanguage[name] = value));
  };

  const handleStringChange = (field, value) => {
    setLanguage((mutableLanguage) => (mutableLanguage.languageStrings[field] = value));
  };

  function renderMain() {
    return (
      <div id="language-edit">
        <Container heading={heading}>
          <fieldset>
            <label className="ampFormLabel" as="legend">
              Locale
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="locale"
              type="text"
              readOnly={language.locale === defaultLocale}
              onChange={handleLocaleChange}
              defaultValue={language.locale}
              error={info.formErrors.locale}
            />
            <ObjectEditor object={language.languageStrings} errors={info.formErrors} onChange={handleStringChange} />
          </fieldset>
        </Container>
      </div>
    );
  }

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={returnUrl}>
            Cancel
          </LinkButton>
          &nbsp;&nbsp;&nbsp;
          {locale && language.locale !== defaultLocale && (
            <LinkButton type="red" onClick={handleDelete}>
              Delete
            </LinkButton>
          )}
        </PageActions>
        {renderMain()}
      </>
    );
  }

  const ready = language && !info.isBusy;
  return <>{ready ? renderContent() : <Loader centerInline />}</>;
}

export default LanguageEdit;
