import Container from "components/Container";
import DynamicList from "components/DynamicList";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import ValidatedInput from "components/ValidatedInput";
import useOrganisation from "lib/organisation/useOrganisation";
import { useUrl } from "lib/site";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import "./index.css";

function EditOrganisation({ organisationId }) {
  const isCreating = organisationId === null;
  const heading = isCreating ? "Create Organisation" : "Edit Organisation";
  const navigate = useNavigate();
  const viewOrganisationsUrl = useUrl()("Organisations");
  const [org, setOrg, api, info, clearDomainErrorByIndex] = useOrganisation(organisationId);

  useEffect(() => {
    info.serverUpdated && navigate(viewOrganisationsUrl);
  }, [info.serverUpdated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrg((mutableOrg) => (mutableOrg[name] = value));
  };

  const handleAddDomain = () => {
    setOrg((o) => (o.allowedEmailDomains = o.allowedEmailDomains ? [...o.allowedEmailDomains, ""] : [""]));
  };

  const handleDeleteDomain = (index) => {
    setOrg((o) => {
      o.allowedEmailDomains = [...o.allowedEmailDomains.filter((e, i) => i !== index)];
    });
    clearDomainErrorByIndex(index);
  };

  const handleDomainChange = (e) => {
    const { name, value } = e.target;
    const dataKey = parseInt(e.target.dataset.datakey);
    setOrg((o) => (o.allowedEmailDomains = o.allowedEmailDomains.map((d, i) => (i === dataKey ? value : d))));
  };

  const handleSave = async () => {
    if (isCreating) {
      if (await api.create()) {
        displayToast(`Organisation ${org.name} created.`);
      }
    } else {
      if (await api.update()) {
        displayToast(`Organisation ${org.name} updated.`);
      }
    }
  };

  const handleDelete = async () => {
    displayAlert(
      `Delete ${org.name}?`,
      `Please confirm you would like to delete the organisation: ${org.name}`,
      alertTypes.confirm,
      async (result) => {
        if (!result.isConfirmed) return;
        try {
          await api.delete();
          displayToast(`Organisation ${org.name} deleted.`);
        } catch {
          displayToast("An error occurred deleting the Organisation.", "error");
        }
      }
    );
  };

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={viewOrganisationsUrl}>
            Cancel
          </LinkButton>
          &nbsp;&nbsp;&nbsp;
          {!isCreating && (
            <LinkButton type="red" onClick={handleDelete}>
              Delete
            </LinkButton>
          )}
        </PageActions>

        <div id="organisation-edit">
          <Container heading={heading}>
            <fieldset>
              <div>
                <label className="ampFormLabel" as="legend">
                  Name
                </label>
                <ValidatedInput
                  className="ampFormControl"
                  name="name"
                  type="text"
                  value={org.name}
                  label="Name"
                  error={info.formErrors.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="ampFormLabel" as="legend">
                  Allowed Email Domains
                </label>
                <DynamicList
                  items={org.allowedEmailDomains?.map((val, index) => (
                    <div key={index} className="email-domain-container">
                      <span className="email-domain-input-label">@</span>
                      <ValidatedInput
                        className="ampFormControl"
                        type="text"
                        value={val}
                        onChange={handleDomainChange}
                        error={info.formErrors.allowedEmailDomains && info.formErrors.allowedEmailDomains[index]}
                        data-datakey={index}
                      />
                    </div>
                  ))}
                  onCreate={handleAddDomain}
                  onDelete={handleDeleteDomain}
                />
              </div>
            </fieldset>
          </Container>
        </div>
      </>
    );
  }

  const ready = (isCreating || org) && !info.isBusy;

  return (
    <>
      <Helmet>
        <title>{"Organisation Management | AMP"}</title>
      </Helmet>
      {ready ? renderContent() : <Loader centerInline />}
    </>
  );
}

export default EditOrganisation;
