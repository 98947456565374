import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { getOrganisations } from "lib/fetch/requests/organisationsRequest";
import useFetch from "lib/fetch/useFetch";
import { useUrl } from "lib/site";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import OrganisationsList from "./OrganisationsList";

const OrganisationManagement = () => {
  const [organisations, loadData, isFetching, error] = useFetch(getOrganisations());
  const getUrl = useUrl();
  const createOrganisationUrl = getUrl("OrganisationCreate");
  
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (error) displayToast("An error occurred fetching organisations.", "error");
  }, [error]);

  const displayOrganisations = () => {
    return !isFetching && 
      <>
        <PageActions>
          <LinkButton to={createOrganisationUrl} type="blue">Create Organisation</LinkButton>  
        </PageActions>
        <OrganisationsList organisations={organisations} />
      </>
    ;
  };

  const displayErrorMessage = () => {
    return (
      !isFetching && (
        <>
          <div className="ampFlexCenter">
            <LinkButton type="blue" onClick={() => loadData()}>
              Refresh
            </LinkButton>
          </div>
        </>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{"Organisations | AMP"}</title>
      </Helmet>
      {isFetching && <Loader centerInline />}
      {error ? displayErrorMessage() : displayOrganisations()}
    </>
  );
};

export default OrganisationManagement;
