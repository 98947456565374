import { useUrl } from "lib/site";
import usePbaasList from "lib/pbaas/usePbaasList";
import Loader from "components/utility/loader/Loader";
import InfoText from "components/InfoText";
import PageActions from "components/PageActions";
import LinkButton from "components/LinkButton";
import Container from "components/Container";
import List from "components/List";

function PbaasApps() {
    const [apps, info] = usePbaasList();
    const getUrl = useUrl();
    const createUrl = getUrl("PbaasAppCreate");
    const appUrlTemplate = getUrl("PbaasAppOverview", {appId: "APP_ID"});
    
    function createListItem(app) {
        return {
            url: appUrlTemplate.replace("APP_ID", app.appId),
            text: app.name 
        }
    }

    function renderApps() {
        if (apps.length === 0) {
            return <InfoText>Your organisation does not have any Pbaas apps. Click 'Create App' to get started. </InfoText> 
        }

        const items = apps.map(createListItem);

        return (
            <Container heading="Pbaas Apps" width={"600px"}>
                <List items={items} heading="Name" keyField="text"/>
            </Container>
        );
    }

    function renderContent() {
        return (
            <>
                <PageActions>
                    <LinkButton to={createUrl} type="blue">Create App</LinkButton>  
                </PageActions>
                {renderApps()}
            </>
        );
    }

    const ready = apps && !info.isBusy 

    return <>{ready ? renderContent() : <Loader centerInline />}</>
}

export default PbaasApps;