import Leaflet from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "../Statistic.css";
import "./Map.css";

// Configure default icon
let DefaultIcon = Leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ height, heading, data }) => {
  return (
    <div className="ampCommonContainer" style={{ height: height }}>
      <p className="statHeading">{heading}</p>
      <MapContainer center={[51.505, -0.09]} zoom={5} minZoom={4} maxZoom={9} scrollWheelZoom={false} style={{ height: "100%" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data && data.map((p) => <Marker position={[p.latitude, p.longitude]}></Marker>)}
      </MapContainer>
    </div>
  );
};

export default Map;
