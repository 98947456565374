import Container from "components/Container";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import ValidatedInput from "components/ValidatedInput";
import ValidatedSelect from "components/ValidatedSelect";
import usePbaasApp from "lib/pbaas/usePbaasApp";
import { useUrl } from "lib/site";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

function PbaasPinBlock() {
  const navigate = useNavigate();
  const getUrl = useUrl();
  const appsUrl = getUrl("PbaasApps");
  const [app, setApp, api, info] = usePbaasApp();

  useEffect(() => {
    info.serverUpdated && navigate(appsUrl);
  }, [info.serverUpdated]);

  const handleSave = async () => {
    if (await api.update()) {
      displayToast(`Pbaas app: ${app.name} updated.`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApp((mutableApp) => (mutableApp[name] = value));
  };

  function renderMain() {
    return (
      <div id="pbaas-app-pinblock" onChange={handleChange}>
        <Container heading="PIN Block Settings">
          <fieldset>
            <label className="ampFormLabel" as="legend">
              PIN Block Format
            </label>
            <ValidatedSelect
              className="ampFormSelect"
              name="pinBlockFormat"
              defaultValue={app.pinBlockFormat}
              error={info.formErrors.pinBlockFormat}>
              <option value="">(Select Format)</option>
              <option value="iso0">ISO 0</option>
              <option value="iso4">ISO 4</option>
            </ValidatedSelect>

            <label className="ampFormLabel" as="legend">
              PIN Block Type
            </label>
            <ValidatedSelect
              className="ampFormSelect"
              name="pinBlockType"
              defaultValue={app.pinBlockType}
              error={info.formErrors.pinBlockType}>
              <option value="">(Select Type)</option>
              <option value="zpk">Zpk</option>
              <option value="bdk">Bdk</option>
            </ValidatedSelect>

            <label className="ampFormLabel" as="legend">
              PIN Block Encryption Key (Under LMK)
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="pinBlockKeyUnderLmk"
              type="text"
              defaultValue={app.pinBlockKeyUnderLmk}
              error={info.formErrors.pinBlockKeyUnderLmk}
            />
          </fieldset>
        </Container>
      </div>
    );
  }

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={appsUrl}>
            Cancel
          </LinkButton>
        </PageActions>
        {renderMain()}
      </>
    );
  }

  return <>{app ? renderContent() : <Loader centerInline />}</>;
}

export default PbaasPinBlock;
