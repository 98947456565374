import "./index.css";

function Container({heading, width, align, children}) {

    align = align || "left";
    width = width || "100%";

    const style = {
        width
    }

    return (
        <div className={"amp-container " + align} style={style}>
            {heading &&  <span className="amp-heading">{heading}</span>}
            {children}
        </div>
    )
}

export default Container