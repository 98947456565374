import useSignOutHelper from "lib/aad/useSignOutHelper";
import useCurrentUserInfo from "lib/fetch/helpers/useCurrentUserInfo";
import { ReactComponent as LogOutIcon } from "lib/icons/log-out.svg";
import React from "react";
import { Container, Navbar } from "react-bootstrap";
import "./index.css";

const Topbar = () => {
  const [userData] = useCurrentUserInfo();
  const { logout } = useSignOutHelper();
  return (
    <Navbar id="topbar">
      <Container id="topbarContainer">
        <div>
          <span>{userData?.displayName}</span>
          <LogOutIcon
            visibility={userData ? "visible" : "hidden"}
            id="logoutIcon"
            title="Log Out"
            onClick={() => {
              logout();
            }}
          />
        </div>
      </Container>
    </Navbar>
  );
};
export default Topbar;
