import "./index.css";
import TextDesigner from "screens/PbaasAppUI/components/TextDesigner";
import PinPadKeyPadDesigner from "./PinPadKeyPadDesigner";

function PbaasPinPadDesigner({pinPad, selectedKey, onSelectElement}) {
    return (
        <div className="pin-pad-designer">
            <TextDesigner 
                className="title"
                element={pinPad.title} 
                selectedKey={selectedKey} 
                onSelectElement={onSelectElement}/>

            <TextDesigner 
                className="body"
                element={pinPad.body} 
                selectedKey={selectedKey} 
                onSelectElement={onSelectElement}/>
            
            <PinPadKeyPadDesigner
                className="keypad" 
                element={pinPad.keyPad} 
                selectedKey={selectedKey} 
                onSelectElement={onSelectElement}/>
        </div>
    );
}

export default PbaasPinPadDesigner