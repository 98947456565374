import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  data: null,
  updatedAt: null,
  fetching: false,
  fetchStartedAt: null,
  errored: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchingUser: (state) => {
      state.fetching = true;
      state.fetchStartedAt = moment();
      state.data = null;
    },
    userFetched: (state, action) => {
      state.data = action.payload;
      state.updatedAt = moment();
      state.fetching = false;
      state.fetchStartedAt = null;
      state.errored = false;
    },
    userFetchErrored: (state) => {
      state.fetching = false;
      state.fetchStartedAt = null;
      state.errored = true;
    },
    loggedOut: () => {},
  },
});

export const { fetchingUser, userFetched, userFetchErrored, loggedOut } = userSlice.actions;

export const selectUserData = (state) => state.user;

export default userSlice.reducer;
