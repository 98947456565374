import { displayToast } from "components/utility/alerts/Toast";
import { listLanguages } from "lib/fetch/requests/languageRequests";
import useFetch from "lib/fetch/useFetch";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function useLanguageList() {
  const { organisationId, appId } = useParams();
  const [list, loadList, isLoading, loadError] = useFetch(listLanguages(organisationId, appId));

  useEffect(() => {
    loadList();
  }, []);

  useEffect(() => {
    if (loadError) displayToast(loadError.message, "error");
  }, [loadError]);

  return [list, { isBusy: isLoading }];
}
