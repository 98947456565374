import moment from "moment";

const LogItem = ({ log, getLogLevel }) => {
  const translatePlatform = (platform) => {
    if (platform === "android") return "Android";
    if (platform === "ios") return "iOS";
    return null;
  };

  const renderIfPresent = (label, value) => {
    return (
      value && (
        <>
          <span>{label}</span>
          <p>{value}</p>
        </>
      )
    );
  };

  return (
    <div className="logitemContainer">
      {renderIfPresent("Timestamp", moment(log.generatedAt)?.toLocaleString())}

      {renderIfPresent("Application Id", log.applicationId)}

      {renderIfPresent("Correlation Id", log.correlationId)}

      {renderIfPresent("Log Level", getLogLevel(log.level))}

      {renderIfPresent("Log Message", log.message)}

      {renderIfPresent("Exception", log.additionalData?.ExceptionMessage)}

      {renderIfPresent("Validation Errors", log.additionalData?.ExceptionData?.ValidationErrors)}

      {renderIfPresent("Device Details", log.additionalData?.DeviceInformation)}

      {renderIfPresent("Cancellation Reason", log.pinEntryCancellationReason)}

      {log.panFirstDigits && renderIfPresent("PAN", `${log.panFirstDigits} **** ${log.panLastDigits}`)}

      {renderIfPresent("Platform", translatePlatform(log.platform))}

      <hr />
    </div>
  );
};

export default LogItem;
