import "./index.css";

const ValidatedSelect = (props) => {
  const { error, children, ...rest } = props;
  return (
    <div className="validated-select">
      <select {...rest} autoComplete="off" >
        {children}
      </select>
      {error && <span className="errorMsg">{error}</span>}
    </div>
  );
};
export default ValidatedSelect;
