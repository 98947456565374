import { displayToast } from "components/utility/alerts/Toast";
import produce from "immer";
import {
  createOrganisation,
  deleteOrganisationById,
  getOrganisationById,
  updateOrganisationById
} from "lib/fetch/requests/organisationsRequest";
import useFetch from "lib/fetch/useFetch";
import useRequest from "lib/fetch/useRequest";
import useUnsavedWarning from "lib/portal/useUnsavedWarning";
import { useEffect, useState } from "react";

export default function useOrganisation(organisationId) {
  const [org, setOrg] = useState(organisationId ? null : { name: "", allowedEmailDomains: [] });
  const [isBusy, setIsBusy] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loadedOrg, loadOrg, isLoading, loadError] = useFetch(getOrganisationById(organisationId));
  const [updatedOrg, updateOrg, isUpdating, updateError] = useFetch(updateOrganisationById(organisationId));
  const [createdOrg, createOrg, isCreating, createError] = useFetch(createOrganisation());
  const [deleteOrg, isDeleting] = useRequest(deleteOrganisationById(organisationId));
  const [setNewInitialState, isDirty] = useUnsavedWarning(org);
  const [serverUpdated, setServerUpdated] = useState(false);

  useEffect(() => {
    if (organisationId) loadOrg();
  }, [organisationId]);

  useEffect(() => {
    if (loadedOrg) {
      setOrg(loadedOrg);
    }
  }, [loadedOrg]);

  useEffect(() => {
    if (loadError) displayToast(loadError.message, "error");
    if (createError) displayToast(createError.message, "error");
    if (updateError) displayToast(updateError.message, "error");
  }, [loadError, createError, updateError]);

  useEffect(() => {
    setIsBusy(isLoading || isCreating || isUpdating || isDeleting);
  }, [isLoading, isCreating, isUpdating, isDeleting]);

  async function updateOrgWrapper() {
    if (!validate()) return false;
    const result = await updateOrg(org);
    setOrg(updatedOrg);
    setNewInitialState(updatedOrg);
    setServerUpdated(true);
    return result;
  }

  async function deleteOrgWrapper() {
    const result = await deleteOrg(organisationId);
    setServerUpdated(true);
    return result;
  }

  async function createOrgWrapper() {
    if (!validate()) return false;
    const result = await createOrg(org);
    setNewInitialState(org);
    setServerUpdated(true);
    return result;
  }

  function setOrgMutable(action) {
    setOrg((prevState) => {
      const nextState = produce(prevState, (draft) => {
        action(draft);
      });

      return nextState;
    });
  }

  function clearDomainErrorByIndex(index) {
    if (!formErrors || !formErrors.allowedEmailDomains) return;
    setFormErrors({
      ...formErrors,
      allowedEmailDomains: [...formErrors.allowedEmailDomains.filter((e, i) => i !== index)],
    });
  }

  console.log(formErrors);
  function validate() {
    const errors = {};
    if (!org.name.trim()) errors.name = "Organisation name is required";
    if (org.name.length > 50) errors.name = "Organisation name cannot exceed 50 characters";

    errors.allowedEmailDomains = [];
    org.allowedEmailDomains &&
      org.allowedEmailDomains.map((d, i) => {
        if (!d.trim()) {
          errors.allowedEmailDomains[i] = "Cannot save empty domain";
        } else if (org.allowedEmailDomains.filter((ad) => ad === d).length > 1) {
          errors.allowedEmailDomains[i] = "Duplicate domains detected";
        }
      });
    setFormErrors(errors);
    return Object.keys(errors).length === 1 && !errors.allowedEmailDomains.length;
  }

  return [
    org,
    setOrgMutable,
    {
      create: createOrgWrapper,
      delete: deleteOrgWrapper,
      update: updateOrgWrapper,
    },
    {
      formErrors,
      isBusy,
      isDirty,
      serverUpdated,
    },
    clearDomainErrorByIndex,
  ];
}
