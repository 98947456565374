import { displayToast } from "components/utility/alerts/Toast";
import { queryLogs } from "lib/fetch/requests/logsRequests";
import useFetch from "lib/fetch/useFetch";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LogsDisplay from "./LogsDisplay";

const LogsModalDisplay = ({ selectedLog, handleClose }) => {
  const { organisationId } = useParams();
  const [modalLogs, fetchModalLogs, fetchingModalLogs, errorFetchingModalLogs] = useFetch(queryLogs(organisationId));
  const [cachedLogs, setCachedLogs] = useState({});
  const [show, setShow] = useState(false);

  const handleModalClose = () => {
    handleClose();
    setShow(false);
  };

  useEffect(() => {
    if (selectedLog) {
      var generatedAt = moment(selectedLog.generatedAt);
      if (!cachedLogs.hasOwnProperty(selectedLog.correlationId))
        fetchModalLogs({
          conditions: {
            correlationId: selectedLog.correlationId,
            from: generatedAt.clone().add(-1, "hour"),
            to: generatedAt.clone().add(1, "hour"),
          },
        });
      setShow(true);
    }
  }, [selectedLog]);

  useEffect(() => {
    if (!fetchingModalLogs && !errorFetchingModalLogs) setCachedLogs({ ...cachedLogs, [selectedLog?.correlationId]: modalLogs });
  }, [modalLogs]);

  useEffect(() => {
    if (errorFetchingModalLogs && !fetchingModalLogs) displayToast("Error occurred fetching logs.", "error");
  }, [errorFetchingModalLogs, fetchingModalLogs]);

  return (
    <>
      <Modal id="dialogModal" show={show} size={"lg"} onHide={handleModalClose}>
        <LogsDisplay loading={fetchingModalLogs} data={cachedLogs[selectedLog?.correlationId]} totalLogs={modalLogs?.length} />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LogsModalDisplay;
