import {  useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import produce from "immer"
import useFetch from "lib/fetch/useFetch";
import useRequest from "lib/fetch/useRequest";
import useUnsavedWarning  from "lib/portal/useUnsavedWarning";
import { listLanguages, updateLanguages, deleteLanguage } from "lib/fetch/requests/languageRequests";
import { displayToast } from "components/utility/alerts/Toast";

export default function useLanguage() {
    const {organisationId, appId, locale} = useParams();
    const [language, setLanguage] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [loadedLanguages, loadLangs, isLoading, loadError] = useFetch(listLanguages(organisationId, appId));
    const [updatedLanguages, updateLangs, isUpdating, updateError] = useFetch(updateLanguages(organisationId, appId));
    const [deleteLang, isDeleting] = useRequest(deleteLanguage(organisationId, appId, locale));
    const [setNewInitialState, isDirty] = useUnsavedWarning(language);
    const [serverUpdated, setServerUpdated] = useState(false);
    const [updateAttempted, setUpdateAttempted] = useState(false);

    useEffect(() => {
        loadLangs();
    }, []);

    useEffect(() =>{
        if (loadedLanguages) {
            const l = locale || 'en';
            let lang = loadedLanguages.filter(lang => lang.locale === l)[0];
            if (!locale) { 
                // creating - create a blank language based on 'en'
                lang = clone(lang);
                lang.locale = "";
                for(const f in lang.languageStrings) {
                    lang.languageStrings[f] = "";
                }
            }
            setLanguage(lang);
        }
    },[loadedLanguages]);

    
    // if an attempt has been made to save, validate whenever language is changed
    // this is so we dont display 'required' validation messages when creating a new language
    useEffect(() => {
        if (updateAttempted) {
            validate();
        }
    }, [language]);


    useEffect(() => {
        if (loadError) displayToast(loadError.message, "error");
        if (updateError) displayToast(updateError.message, "error");
    }
    , [loadError, updateError])


    useEffect(() => {
        setIsBusy(isLoading || isUpdating || isDeleting)
    }
    , [isLoading, isUpdating, isDeleting])


    async function updateWrapper() {
        setUpdateAttempted(true);

        if (!validate()) return false;

        let languages = loadedLanguages;
        if (locale) // updating
        {
            languages = languages.filter(l => l.locale !== locale);
        }
        languages = [...languages, language];
        
        const result = await updateLangs(languages);
        
        if (result) {
            setNewInitialState(language);
            setServerUpdated(true);
        }
        
        return result;
    }

    async function deleteWrapper() {
        const result = await deleteLang();
        setNewInitialState(language);
        setServerUpdated(true);
        return result;
    }

    function setLanguageMutable (action)  {
        setLanguage(prevState => {
            const nextState = produce(prevState, draft => {
                action(draft);
            });

            return nextState;
        });
    };

    function validate() {
        const errors = {};
        if (!language.locale.trim()) errors.locale = "Locale is required.";
        if (language.locale.length > 10) errors.locale = "Locale cannot exceed 10 characters.";
        
        for(const name of Object.keys(language.languageStrings)){
            const value = language.languageStrings[name];
        
            if (!value.trim()) errors[name] = name + " is required.";
            else if (value.length > 100) errors[name] = name + " cannot exceed 100 characters.";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    return [
        language, 
        setLanguageMutable, 
        {
            delete: deleteWrapper,
            update : updateWrapper,
            validate
        },
        {
            formErrors,
            isBusy,
            isDirty,
            serverUpdated
        }
    ];
}

function clone(o) {
    const json = JSON.stringify(o);
    return JSON.parse(json);
}