import { httpMethods } from "./util";

export function getStatisticsByOrganisationId(organisationId, from, to) {
  console.log(from);

  return {
    url: `statistics/organisation/${organisationId}/hourly?startUtc=${from.toISOString()}&endUtc=${to.toISOString()}`,
    options: { method: httpMethods.get },
  };
}

export function getStatisticsByApplicationId(organisationId, appId, from, to) {
  return {
    url: `statistics/organisation/${organisationId}/application/${appId}/hourly?startUtc=${from.toISOString()}&endUtc=${to.toISOString()}`,
    options: { method: httpMethods.get },
  };
}
