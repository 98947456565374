import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import useSignOutHelper from "lib/aad/useSignOutHelper";
import { useEffect } from "react";
import Swal from "sweetalert2";

const IdleLogOut = ({ warningDuration = 10000 }) => {
  const { logout } = useSignOutHelper();

  useEffect(() => {
    let timerInterval;
    displayAlert(
      "<b></b>",
      `Logging out shortly as no activity has been detected for a while.`,
      {
        ...alertTypes.warning,
        showConfirmButton: false,
        timer: warningDuration,
        timerProgressBar: true,
        didOpen: () => {
          const b = Swal.getTitle().querySelector("b");
          timerInterval = setInterval(() => {
            let timeRemaning = parseInt(Swal.getTimerLeft() / 1000);
            b.textContent = isNaN(timeRemaning) ? 0 : timeRemaning;
          }, 100);
        },
      },
      (e) => e.dismiss === "timer" && logout()
    );
    return () => {
      clearInterval(timerInterval);
      Swal.close();
    };
  }, []);

  return <></>;
};

export default IdleLogOut;
