import "./index.css";
import SelectableElement from "../SelectableElement";

function TextButtonDesigner({element, selectedKey, className, onSelectElement}) {

    const style = {
        color: element.fontColor,
        fontFamily: element.fontName,
        fontSize: element.fontSize + "px"
    }

    return (
        <>
            {element && 
            <div className={"text-button " + className}>
                <SelectableElement 
                    element={element} 
                    isSelected={element.key === selectedKey} 
                    onSelectElement={onSelectElement}> 
                        <button style={style}>{element.displayText}</button>
                </SelectableElement>
            </div>
            }
        </>
    );
}

export default TextButtonDesigner
