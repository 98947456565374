import Container from "components/Container";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import { displayToast } from "components/utility/alerts/Toast";
import PaginationComponent from "components/utility/pagination/Pagination";
import { getOrganisations } from "lib/fetch/requests/organisationsRequest";
import { getUsersForOrganisation } from "lib/fetch/requests/userRequests";
import useFetch from "lib/fetch/useFetch";
import { getAdminOrgId, useUrl } from "lib/site";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import "./index.css";
import UsersList from "./UsersList";

const Users = ({ organisationId, createUserScreen, editUserScreen, displayOrganisationsFilter = true }) => {
  const defaullFilters = {
    searchText: "",
    organisationId: displayOrganisationsFilter ? "" : organisationId,
    resultsLimit: 50,
  };

  const [filters, setFilters] = useState({ ...defaullFilters });
  const [users, getUsers, isFetching, error] = useFetch(getUsersForOrganisation(organisationId));
  const getUrl = useUrl();
  const [currentPage, setCurrentPage] = useState(1);
  const [latestSearchedFilters, setLatestSearchedFilters] = useState();

  useEffect(() => {
    // 1000 ms delay before executing request
    const delayRequest = setTimeout(refreshUsers, users && !error ? 1000 : 0);
    return () => clearTimeout(delayRequest);
  }, [filters]);

  // force data refresh if filters have been updated since the last successful request
  useEffect(() => {
    if (
      !isFetching &&
      latestSearchedFilters &&
      filters &&
      (latestSearchedFilters.searchText !== filters.searchText || filters.resultsLimit !== latestSearchedFilters.resultsLimit)
    ) {
      refreshUsers();
    }
  }, [users, isFetching]);

  const refreshUsers = () => {
    setLatestSearchedFilters(filters);
    getUsers(filters);
  };

  useEffect(() => {
    if (error) displayToast("An error occurred fetching users.", "error");
  }, [error]);

  useEffect(() => {
    setCurrentPage(1);
  }, [users]);

  const displayUsers = () => {
    var filtered = users;
    if (users && process.env.REACT_APP_EXCLUDE_USER) {
      filtered = users.filter((d) => d.displayName !== process.env.REACT_APP_EXCLUDE_USER);
    }
    var itemsPerPage = 10;
    var offsetIndex = itemsPerPage * (currentPage - 1);
    filtered = filtered?.slice(offsetIndex, offsetIndex + itemsPerPage);
    return (
      <Container>
        <UsersSearch displayOrganisationsFilter={displayOrganisationsFilter} filters={filters} setFilters={setFilters} />
        <UsersList users={filtered} editUserScreen={editUserScreen} isFetching={isFetching} />
        <PaginationComponent
          itemsCount={users?.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    );
  };

  const displayErrorMessage = () => {
    return (
      !isFetching && (
        <>
          <div className="ampFlexCenter">
            <Button variant="outline-primary" size="lg" onClick={() => getUsers()}>
              Refresh
            </Button>
          </div>
        </>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{"User Management | AMP"}</title>
      </Helmet>
      {error && displayErrorMessage()}
      <>
        {createUserScreen && (
          <PageActions>
            <LinkButton type="blue" to={getUrl(createUserScreen)}>
              Create User
            </LinkButton>
          </PageActions>
        )}
        {displayUsers()}
      </>
    </>
  );
};

const UsersSearch = ({ displayOrganisationsFilter, filters, setFilters }) => {
  const [organisations, loadOrganisations, isFetchingOrganisations, errorFetchingOrganisations] = useFetch(getOrganisations());

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (displayOrganisationsFilter) loadOrganisations();
  }, []);

  return (
    <div id="usersSearchDiv">
      <div>
        <input
          onChange={handleChange}
          value={filters.searchText ?? ""}
          placeholder="Search by Name or Email"
          className="ampFormControl"
          name="searchText"
          type="text"></input>
      </div>
      {displayOrganisationsFilter && (
        <div className="userSearchFilterDiv">
          <select className="ampFormSelect" name="organisationId" onChange={handleChange} value={filters.organisationId ?? ""}>
            <option disabled>Organisation Filter</option>
            <option value="">All</option>
            <option value={getAdminOrgId()}>MYPINPAD</option>
            {organisations && organisations.map((org) => <option value={org.id}>{org.name}</option>)}
          </select>
        </div>
      )}

      <div className="userSearchFilterDiv">
        <select className="ampFormSelect" name="resultsLimit" onChange={handleChange} value={filters.resultsLimit ?? ""}>
          <option disabled>Number of Users</option>
          <option value={10}>Top 10 Users</option>
          <option value={25}>Top 25 Users</option>
          <option value={50}>Top 50 Users</option>
        </select>
      </div>
    </div>
  );
};

export default Users;
