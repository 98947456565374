import { CartesianGrid, Legend, Line, LineChart as Chart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./Statistic.css";

const LineChart = ({ height, heading, data, dataKey, valueConfig }) => {
  return (
    <div className="ampCommonContainer" style={{ height: height }}>
      <p className="statHeading">{heading}</p>
      <ResponsiveContainer debounce={300} width={"95%"} height="90%">
        <Chart data={data}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey={dataKey} />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          {valueConfig?.map((config) => {
            return (
              <Line name={config.name} type="monotone" key={config.dataKey} dataKey={config.dataKey} stroke={config.stroke} />
            );
          })}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
