import Loader from "components/utility/loader/Loader";
import moment from "moment";
import { useEffect, useState } from "react";
import LogItem from "./LogItem";

const LogsDisplay = ({ data, loading, totalLogs, setSelectedLog, expandedFirstRow = false }) => {
  const [expandedIds, setExpandedIds] = useState([]);

  useEffect(() => {
    setExpandedIds([]);
  }, [data, loading]);

  useEffect(() => {
    if (expandedFirstRow && data && data.length) {
      setExpandedIds([...expandedIds, data[0].id]);
    }
  }, [data]);

  const getLogLevel = (levelEnum) => {
    switch (levelEnum) {
      case 0:
        return "Information";
      case 1:
        return "Business Error";
      case 2:
        return "System Error";
    }
  };

  return (
    <div className="ampCommonContainer" style={{ border: "none", paddingBottom: 0 }}>
      {!loading && totalLogs > 0 && <p id="logsCountLabel">{totalLogs} logs found.</p>}
      <table id="logsTable" className="ampTable nowrap ampFontSizeSmall">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Correlation Id</th>
            <th>Log Level</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.map((log, index) => (
              <>
                <tr
                  className={`ampClickable ${expandedIds.includes(index) ? "expandedRowParent" : ""}`}
                  key={index}
                  onClick={() =>
                    setExpandedIds(
                      expandedIds.includes(index) ? [...expandedIds.filter((x) => x !== index)] : [...expandedIds, index]
                    )
                  }>
                  <td>{moment(log.generatedAt).toLocaleString()}</td>
                  <td>
                    {setSelectedLog ? (
                      <span
                        className="ampLink"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedLog(log);
                        }}>
                        {log.correlationId}
                      </span>
                    ) : (
                      log.correlationId
                    )}
                  </td>
                  <td>{getLogLevel(log.level)}</td>
                  <td>{log.message}</td>
                </tr>
                {expandedIds && expandedIds.includes(index) && (
                  <tr className="expandedRow">
                    <td colSpan={5}>
                      <LogItem log={log} getLogLevel={getLogLevel} />
                    </td>
                  </tr>
                )}
              </>
            ))}

          {(!data || !data.length) && !loading && (
            <tr>
              <td className="text-start" colSpan={4}>
                <span>No logs found.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Loader centerInline visible={loading} size={"md"} />
    </div>
  );
};

export default LogsDisplay;
