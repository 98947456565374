import { displayToast } from "components/utility/alerts/Toast";
import produce from "immer";
import {
  createUser,
  deleteUserById,
  getAllowedEmailDomains,
  getPermissions,
  getUserById,
  updateUserById
} from "lib/fetch/requests/userRequests";
import useFetch from "lib/fetch/useFetch";
import useRequest from "lib/fetch/useRequest";
import useUnsavedWarning from "lib/portal/useUnsavedWarning";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function useUser(organisationId) {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loadedUser, loadUser, isLoading, loadError] = useFetch(getUserById(organisationId, userId));
  const [updateUser, isUpdating] = useRequest(updateUserById(organisationId, userId));
  const [create, isCreating] = useRequest(createUser(organisationId));
  const [deleteUser, isDeleting] = useRequest(deleteUserById(organisationId, userId));
  const [loadedPermissions, loadPermissions, isLoadingPermissions, loadPermissionsError] = useFetch(
    getPermissions(organisationId)
  );
  const [allowedDomains, loadAllowedDomains, isLoadingDomains, loadDomainsError] = useFetch(
    getAllowedEmailDomains(organisationId)
  );
  const [setNewInitialState, isDirty] = useUnsavedWarning(user);
  const [serverUpdated, setServerUpdated] = useState(false);

  useEffect(() => {
    loadPermissions();
    loadAllowedDomains();
    if (userId) loadUser();
  }, []);

  useEffect(() => {
    if (loadedPermissions && (loadedUser || !userId)) {
      setUser(createUserObject(loadedUser, loadedPermissions));
    }
  }, [loadedPermissions, loadedUser]);

  useEffect(() => {
    if (loadError) displayToast(loadError.message, "error");
    if (loadPermissionsError) displayToast(loadPermissionsError.message, "error");
  }, [loadError, loadPermissionsError]);

  useEffect(() => {
    setIsBusy(isLoading || isCreating || isUpdating || isDeleting || isLoadingPermissions || isLoadingDomains);
  }, [isLoading, isCreating, isUpdating, isDeleting, isLoadingPermissions, isLoadingDomains]);

  function createUserObject(user, permissions) {
    const u = { ...user };
    u.permissions = permissions.map((p) => {
      return {
        ...p,
        checked: user?.permissions.some((uperm) => uperm.code === p.code) ?? false,
      };
    });

    return u;
  }

  function prepareUserObjectForSaving(userObject) {
    return {
      ...userObject,
      permissions: userObject.permissions.filter((p) => p.checked).map((p) => p.code),
    };
  }

  async function updateUserWrapper() {
    if (!validate()) return false;

    try {
      await updateUser(prepareUserObjectForSaving(user));
      setNewInitialState(user);
      setServerUpdated(true);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function deleteUserWrapper() {
    try {
      await deleteUser();
      setNewInitialState(user);
      setServerUpdated(true);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function createUserWrapper() {
    if (!validate()) return false;

    try {
      await create(prepareUserObjectForSaving(user));
      setNewInitialState(user);
      setServerUpdated(true);
      return true;
    } catch (e) {
      return false;
    }
  }

  function setUserMutable(action) {
    setUser((prevState) => {
      const nextState = produce(prevState, (draft) => {
        action(draft);
      });

      return nextState;
    });
  }

  function validate() {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const errors = {};

    if (!user.email || !user.email.trim()) errors.email = "Email is required.";
    else if (user.email.length > 255) errors.email = "Email cannot exceed 255 characters.";
    else if (!user.email.match(emailRegex)) errors.email = "Email is not a valid email address.";
    else {
      var domain = user.email.split("@")[1].toLowerCase();
      if (allowedDomains?.indexOf(domain) === -1)
        errors.email = "Only the following email domains are allowed: " + allowedDomains.map((d) => "@" + d).join(", ");
    }

    if (!user.displayName || !user.displayName.trim()) errors.displayName = "Display Name is required.";
    else if (user.displayName.length > 30) errors.displayName = "Display Name cannot exceed 30 characters.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  return [
    user,
    setUserMutable,
    {
      create: createUserWrapper,
      delete: deleteUserWrapper,
      update: updateUserWrapper,
    },
    {
      formErrors,
      isBusy,
      isDirty,
      serverUpdated,
      allowedDomains,
    },
  ];
}
