import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  data: null,
  updatedAt: null,
  fetching: false,
  fetchStartedAt: null,
  errored: false,
};

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    fetchingOrganisation: (state) => {
      state.fetching = true;
      state.fetchStartedAt = moment();
      state.data = null;
    },
    organisationfetched: (state, action) => {
      state.data = action.payload;
      state.updatedAt = moment();
      state.fetching = false;
      state.fetchStartedAt = null;
      state.errored = false;
    },
    organisationFetchErrored: (state) => {
      state.fetching = false;
      state.fetchStartedAt = null;
      state.errored = true;
    },
  },
});

export const { fetchingOrganisation, organisationfetched, organisationFetchErrored } = organisationSlice.actions;

export const selectOrganisationData = (state) => state.organisation;

export default organisationSlice.reducer;
