import { useParams } from "react-router-dom";
import Logs from "screens/components/Logs";

// todo: This is just a copy of org logs page. Need to make it so that it is logs for a specific app.
function PbaasAppLogs() {
  const { organisationId, appId } = useParams();
  return <Logs organisationId={organisationId} appId={appId} />;
}

export default PbaasAppLogs;
