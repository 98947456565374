import Icons from "lib/icons";
import { useMenuItems } from "lib/site";
import { NavLink } from "react-router-dom";
import "./index.css";

const Sidebar = () => {
  const menuItems = useMenuItems();

  function renderItem(item) {
    var Icon = Icons[item?.icon];
    return (
      <li key={item.key}>
        <NavLink to={item.path} activeclassname="active">
          <span className="sidebar-icon">{Icon && <Icon />}</span>
          <span className="sidebar-text">{item.text}</span>
        </NavLink>
      </li>
    );
  }

  return (
    <div id="sidebar">
      <div id="brand" className="bg-white">
        <a href="/">
          <img src="/assets/images/logos/logo.png" className="img-fluid" />
        </a>
      </div>
      <ul>{menuItems.map(renderItem)}</ul>
    </div>
  );
};
export default Sidebar;
