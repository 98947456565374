import { useParams } from "react-router-dom";
import UserEdit from "screens/components/UserEdit"
import { useUrl }  from "lib/site"

function OrganisationUserCreate() {
    const getUrl = useUrl();
    const { organisationId } = useParams();
    return <UserEdit organisationId={organisationId} returnUrl={getUrl("OrganisationUsers")} />
}

export default OrganisationUserCreate;