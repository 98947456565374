import { displayToast } from "components/utility/alerts/Toast";
import PaginationComponent from "components/utility/pagination/Pagination";
import { countLogs, queryLogs } from "lib/fetch/requests/logsRequests";
import useFetch from "lib/fetch/useFetch";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./index.css";
import LogsDisplay from "./LogsDisplay";
import LogsFilters from "./LogsFilters";
import LogsModalDisplay from "./LogsModalDisplay";

const Logs = ({ organisationId, appId }) => {
  const limit = 10;
  const defaullFilters = {
    from: moment().startOf("day"),
    to: moment().endOf("day"),
    applicationId: appId,
  };
  const [filters, setFilters] = useState({ ...defaullFilters });
  const [currentPage, setCurrentPage] = useState(1);
  const [logs, fetchLogs, fetchingLogs, errorFetchingLogs] = useFetch(queryLogs(organisationId));
  const [logsCount, fetchLogsCount, fetchingLogsCount] = useFetch(countLogs(organisationId));
  const [cachedLogs, setCachedLogs] = useState({});
  const [selectedLog, setSelectedLog] = useState(null);

  const updateFilters = (updateObj) => {
    setFilters({
      ...filters,
      ...updateObj,
    });
  };

  const refreshLogs = async () => {
    await fetchLogs({ conditions: filters, limit, offset: limit * (currentPage - 1) });
  };

  const refreshLogsCount = async () => {
    await fetchLogsCount({ conditions: filters });
  };

  const resetFilters = () => {
    setFilters({ ...defaullFilters });
  };

  useEffect(() => {
    if (!fetchingLogs && !errorFetchingLogs) setCachedLogs({ ...cachedLogs, [currentPage]: logs });
  }, [logs]);

  useEffect(() => {
    setCachedLogs({});
    setCurrentPage(1);
    refreshLogs();
    refreshLogsCount();
  }, [filters]);

  useEffect(() => {
    // check if the page has already been retrieved
    if (cachedLogs.hasOwnProperty(currentPage)) return;
    refreshLogs();
  }, [currentPage]);

  useEffect(() => {
    if (errorFetchingLogs && !fetchingLogs) displayToast("Error occurred fetching logs.", "error");
  }, [errorFetchingLogs, fetchingLogs]);

  return (
    <>
      <Helmet>
        <title>{"Logs | AMP"}</title>
      </Helmet>
      <div className="ampCommonContainer">
        <LogsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} displayAppIdFilter={!appId} />
        <br />
        <LogsDisplay
          setSelectedLog={setSelectedLog}
          data={cachedLogs[currentPage]}
          loading={fetchingLogs || fetchingLogsCount}
          totalLogs={logsCount}
        />
        <PaginationComponent
          itemsCount={logsCount}
          itemsPerPage={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <LogsModalDisplay selectedLog={selectedLog} handleClose={() => setSelectedLog(null)} />
    </>
  );
};

export default Logs;
