import { ReactComponent as Activity } from "./activity.svg";
import { ReactComponent as AlertTriangle } from "./alert-triangle.svg";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowUp } from "./arrow-up.svg";
import { ReactComponent as BarChart } from "./bar-chart-2.svg";
import { ReactComponent as CheckSquare } from "./check-square.svg";
import { ReactComponent as FileText } from "./file-text.svg";
import { ReactComponent as Globe } from "./globe.svg";
import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Layers } from "./layers.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as LogOut } from "./log-out.svg";
import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as Monitor } from "./monitor.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as XOctagon } from "./x-octagon.svg";
import { ReactComponent as X } from "./x.svg";
import { ReactComponent as Zap } from "./zap.svg";

const Icons = {
  Activity,
  AlertTriangle,
  ArrowDown,
  ArrowUp,
  BarChart,
  CheckSquare,
  FileText,
  Globe,
  Home,
  Info,
  Layers,
  Lock,
  LogOut,
  Menu,
  Monitor,
  User,
  Users,
  XOctagon,
  X,
  Zap,
};

export default Icons;
