import "./index.css";

function SelectableElement({ isSelected, element, onSelectElement, children }) {
  const handleClick = (e) => {
    e.stopPropagation();
    if (!isSelected) onSelectElement(element.key);
  };

  function getClassName() {
    const classNames = ["selectable-element"];
    if (isSelected) classNames.push("selected");
    return classNames.join(" ");
  }

  return (
    <div className={getClassName()} onClick={handleClick}>
      {children}
    </div>
  );
}

export default SelectableElement;
