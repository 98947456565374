import "./index.css";
import PropertyGridColorEditor  from './PropertyGridColorEditor';

function PropertyGrid({propertyList, lookups, onChange}) {

    const handleChange = (e) => {
        const name = e.target.name;
        const value = +e.target.value || e.target.value;
        onChange(name, value);
    }

    function renderInputProperty(p) {
        return <input name={p.name} value={p.value} className="property-grid-input" onChange={handleChange}/>;
    }

    function renderColorProperty(p) {
        return  <PropertyGridColorEditor name={p.name} color={p.value} onChange={onChange} />;
    }

    function renderLookupProperty(p, lookup) {
        return (
            <select name={p.name} value={p.value} className="property-grid-input" onChange={handleChange}>
                {lookup.map(i => <option value={i}>{i}</option>)}
            </select>
        );
    }

    function renderPropertyValue(p) {
        if (lookups[p.type]) return renderLookupProperty(p, lookups[p.type]);
        if (p.type === "color") return renderColorProperty(p);
        return renderInputProperty(p);
    };

    return (
        <>
            {propertyList?.length &&
                <div className="property-grid">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2">Properties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {propertyList.map(p => {
                                return (
                                    <tr key={p.name}>
                                        <td className="property-name">{p.name}</td>
                                        <td className="property-value">{renderPropertyValue(p)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}

export default PropertyGrid;