import ImageButtonDesigner from "screens/PbaasAppUI/components/ImageButtonDesigner";
import SelectableElement from "screens/PbaasAppUI/components/SelectableElement";
import "./index.css";

function PinPadKeyPadDesigner({ element, selectedKey, className, onSelectElement }) {
  const keyPadStyle = {
    backgroundColor: element.backgroundColor,
  };

  const buttonStyle = {
    color: element.fontColor,
    fontFamily: element.fontName,
    fontSize: element.fontSize + "px",
  };

  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, "clear", 0, "enter"].map(createButton);

  function createButton(text) {
    if (text === "clear") {
      return <ImageButtonDesigner element={element.buttonClear} selectedKey={selectedKey} onSelectElement={onSelectElement} />;
    }

    if (text === "enter") {
      return <ImageButtonDesigner element={element.buttonEnter} selectedKey={selectedKey} onSelectElement={onSelectElement} />;
    }

    return (
      <div key={text} className={"key-pad-button button" + text} style={buttonStyle}>
        {text}
      </div>
    );
  }

  return (
    <>
      {element && (
        <div className={className + " pin-pad-key-pad"} style={keyPadStyle}>
          <SelectableElement element={element} isSelected={element.key === selectedKey} onSelectElement={onSelectElement}>
            <div className="button-container">{buttons}</div>
          </SelectableElement>
        </div>
      )}
    </>
  );
}

export default PinPadKeyPadDesigner;
