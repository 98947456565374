import { useUrl } from "lib/site";
import { useParams } from "react-router-dom";
import UserEdit from "screens/components/UserEdit";

function AllUsersEdit() {
  const getUrl = useUrl();
  const { organisationId } = useParams();
  return <UserEdit organisationId={organisationId} returnUrl={getUrl("AllUsers")} />;
}

export default AllUsersEdit;
