import "./index.css";

function PageActions({children}) {
    return (
        <div className="page-actions">
            {children}
        </div>
    )
}

export default PageActions;
