import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "./useFetch";

export default function useCachedFetch({
  request,
  stateSelector,
  cacheDurationInMinutes,
  fetchAction,
  updateAction,
  errorAction,
}) {
  const [fetchedData, fetch, fetching, error] = useFetch(request);
  const dispatch = useDispatch();
  const cachedState = useSelector((state) => stateSelector(state));

  useEffect(() => {
    if (fetchedData && !error && !fetching) {
      dispatch(updateAction(fetchedData));
    } else if (error && fetching) {
      dispatch(errorAction());
    }
  }, [fetchedData, error]);

  const refresh = async (force = false, retryAttempts = 0) => {
    if (
      force ||
      !cachedState.data ||
      !cachedState.updatedAt ||
      moment().diff(moment(cachedState.updatedAt), "minute") > parseInt(cacheDurationInMinutes) ||
      isLastFetchIncomplete()
    ) {
      if (force || isLastFetchIncomplete() || (!cachedState.fetching && !fetching)) {
        dispatch(fetchAction());
        await fetch(retryAttempts);
      }
    }
  };

  const isLastFetchIncomplete = () => {
    return (
      (fetching || cachedState.fetching) &&
      (!cachedState.lastFetchedAt || moment().diff(moment(cachedState.fetchStartedAt), "minute") > parseInt(1))
    );
  };
  return [cachedState.data, refresh, cachedState.fetching, cachedState.errored];
}
