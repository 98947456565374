import { httpMethods } from "./util";

export function listLanguages(organisationId, appId) {
  return {
      url: `Language/organisation/${organisationId}/${appId}`,
      options: { method: httpMethods.get },
    };
}

export function updateLanguages(organisationId, appId) {
    return {
        url: `Language/organisation/${organisationId}/${appId}`,
        options: { method: httpMethods.post },
      };
}

export function deleteLanguage(organisationId, appId, locale) {
    return {
        url: `Language/organisation/${organisationId}/${appId}/locale/${locale}`,
        options: { method: httpMethods.delete },
      };
}