import { httpMethods } from "./util";

export function createPbaasApp(organisationId) {
  return {
    url: `AppRegistration/pbaas/organisation/${organisationId}`,
    options: { method: httpMethods.post },
  };
}

export function getPbaasApp(organisationId, appId) {
    return {
        url: `AppRegistration/pbaas/organisation/${organisationId}/${appId}`
      };
}

export function deletePbaasApp(organisationId, appId) {
    return {
        url: `AppRegistration/pbaas/organisation/${organisationId}/${appId}`,
        options: { method: httpMethods.delete },
      };
}

export function updatePbaasApp(organisationId) {
    return {
        url: `AppRegistration/pbaas/organisation/${organisationId}`,
        options: { method: httpMethods.put },
      };
}

export function listPbaasApps(organisationId) {
    return {
        url: `AppRegistration/pbaas/organisation/${organisationId}`,
        options: { method: httpMethods.get },
      };
}