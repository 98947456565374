import "bootstrap/dist/css/bootstrap.min.css";
import Breadcrumb from "components/Breadcrumb";
import IdleLogOut from "components/IdleLogOut";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import Loader from "components/utility/loader/Loader";
import useReadiness from "lib/helpers/readiness/useReadiness";
import useSessionTimeout from "lib/session/useSessionTimeout";
import RouteRenderer from "lib/site/RouteRenderer";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";

function App() {
  const { isReady } = useReadiness();
  const [isActive, setIsActive] = useState(true);
  useSessionTimeout(setIsActive, 900, 30);

  const renderMainScreen = () => {
    return (
      <>
        <Topbar />
        <Sidebar />
        <div id="app">
          <Breadcrumb />
          <RouteRenderer />
        </div>
        {!isActive && <IdleLogOut />}
      </>
    );
  };

  const renderAwaitingRedirectScreen = () => {
    return (
      <>
        <Topbar />
        <Sidebar />
        <Loader visible={true} centerAbsoloute={true} size="lg" />
      </>
    );
  };

  return (
    <div id="appContainer">
      <ToastContainer />
      {isReady ? renderMainScreen() : renderAwaitingRedirectScreen()}
    </div>
  );
}

export default App;
