import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { inProgressActiveStatuses } from "lib/constants/aad/msalConfig";
import useCurrentUserInfo from "lib/fetch/helpers/useCurrentUserInfo";
import { initSite } from "lib/site";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function useReadiness() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userData, refresh, isFetching, userFetchErrored] = useCurrentUserInfo(false);
  const [siteDataLoaded, setSiteDataLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // return if already processing an event
    if (inProgressActiveStatuses.includes(inProgress)) return;
    else if (isAuthenticated) refresh();
    else if (!isAuthenticated) instance.loginRedirect({});
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    if (isAuthenticated && !isFetching && userFetchErrored && inProgress === "none") {
      displayAlert(
        "Error",
        "An error has occurred. Please try to sign in again.",
        {
          ...alertTypes.error,
          confirmButtonText: "Logout",
          allowOutsideClick: false,
          allowEscapeKey: false,
        },
        (result) => {
          if (result.isConfirmed) instance.logoutRedirect();
        }
      );
    } else if (isAuthenticated && !userFetchErrored && !isFetching) {
      Swal.close();
    }
  }, [inProgress, userFetchErrored, isAuthenticated, isFetching]);

  useEffect(() => {
    const initSiteData = async () => {
      if (userData) {
        await initSite(userData);
        setSiteDataLoaded(true);
      }
    };

    initSiteData();
  }, [userData]);

  useEffect(() => {
    var currentReadinesState = isAuthenticated && userData && siteDataLoaded;
    if (currentReadinesState !== isReady) setIsReady(currentReadinesState);
  }, [isAuthenticated, userData, siteDataLoaded]);

  return { isReady };
}
