import { Oval } from "react-loader-spinner";
import "./Loader.css";

const Loader = ({ visible, centerAbsoloute = false, centerInline = false, size = "md"}) => {
  const getClassName = () => {
    let classNames = ["flex"];
    if (centerAbsoloute) classNames.push("centerAbsoloute");
    if (centerInline) classNames.push("centerInline");
    return classNames.join(" ");
  };

  const getSize = () => {
    switch (size) {
      case "sm":
        return {
          height: "2rem",
        };
      case "md":
        return {
          height: "2.5rem",
        };
      case "lg":
        return {
          height: "3.5rem",
        };
    }
  };

  return (
    <div className={getClassName()}>
      <Oval
        {...getSize()}
        color="#8A8A8A"
        visible={visible}
        ariaLabel="oval-loading"
        secondaryColor="#D0D1D2"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};
export default Loader;
