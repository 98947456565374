import "./index.css";
import { Link } from "react-router-dom";

function List({items, heading, keyField}) {

    function renderItem(i) {
        keyField = keyField || "text";
        const key = i[keyField];

        return (
            <li key={key}>
                <Link to= {i.url}>
                    {i.text}
                </Link>
            </li>
        )
    }

    return (
        <div className="list">
            <div className="heading">{heading}</div>
            <ul>
                {items.map(renderItem)}
            </ul>
        </div>
    )
}

export default List