import useAccessToken from "lib/aad/useAccessTokenHelper";
import { useState } from "react";
import fetchData from "./fetch";

export default function useRequest({ url, options }) {
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken } = useAccessToken();

  const execute = async (body) => {
    setIsLoading(true);
    try {
      var token = await getAccessToken();
      await fetchData({ token, url, options, body });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  return [execute, isLoading];
}
