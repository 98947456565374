import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "lib/fetch/useFetch";
import { listLanguages } from "lib/fetch/requests/languageRequests";

export default function useLanguageTranslate() {
    const {organisationId, appId} = useParams();
    const [currentLocale, setCurrentLocale] = useState(null);
    const [locales, setLocales] = useState([]);
    const [languages, loadLanguages, isLoading, loadError] = useFetch(listLanguages(organisationId, appId));

    useEffect(() => {
        loadLanguages();
    }, []);

    useEffect(() => {
        if (languages) {
            setLocales(languages.map(l => l.locale));
            setCurrentLocale("en");
        }
    }, [languages]);

    function getText(textTemplate) {
        const language = languages.filter(s => s.locale === currentLocale)[0];
        
        for (const name in language.languageStrings) {
            const value = language.languageStrings[name];
            textTemplate = textTemplate.replace("#" + name, value);
        }
        return textTemplate;
    }

    return [currentLocale, setCurrentLocale, locales, getText]
}