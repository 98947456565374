import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import organisationReducer from "./organisation/organisationSlice";
import userReducer from "./user/userSlice";

const reducers = combineReducers({
  user: userReducer,
  organisation: organisationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/loggedOut") {
    // this applies to all keys defined in persistConfig(s)
    storageSession.removeItem(`persist:${process.env.REACT_APP_REDUX_STORE_KEY}`);
    state = {};
  }
  return reducers(state, action);
};

const persistConfig = {
  key: process.env.REACT_APP_REDUX_STORE_KEY,
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});
