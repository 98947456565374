import { useBreadcrumbs } from "lib/site";
import { NavLink } from 'react-router-dom';
import "./index.css";

function Breadcrumb() {
    var breadcrumbs = useBreadcrumbs();

    function renderItem(i, index) {
        var isFirst = index == 0;

        return (
            <span key={i.key}>
                {!isFirst && <span>&gt;&nbsp;&nbsp;</span>}
                {i.path ? 
                    <NavLink to={i.path}>{i.text}</NavLink>
                    :
                    <span className="page-title">{i.text}</span>
                }
            </span>
        );
    }

    return (
        <div className="breadcrumb">
            {breadcrumbs.map(renderItem)}
        </div>
    );
}

export default Breadcrumb;