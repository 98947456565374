import "./index.css";
import { useState } from 'react';
import { ChromePicker as ColorPicker } from 'react-color';

function PropertyGridColorEditor({name, color, onChange}) {
    const [showPicker, setShowPicker] = useState(false);

    const handleShow = () => {
        setShowPicker(true);
    };
    
    const handleClose = e => {
        e.stopPropagation();
        setShowPicker(false);
    };

    const handlePickerChange = (color) => {
        onChange(name, color.hex);
    };

    const colorDisplayStyle = {
        backgroundColor : color,
    };

    return (
        <div className="property-grid-color-editor" onClick={handleShow}>
            <div className="color-text" >
                {color} 
            </div>
            <div className="color-display" style={colorDisplayStyle}/>
            {showPicker && 
                <div className="popover">
                    <div className="cover" onClick={handleClose}/>
                    <ColorPicker color={color} onChangeComplete={handlePickerChange} disableAlpha={true}/>
                </div>
            }
        </div>
    )
}

export default PropertyGridColorEditor;