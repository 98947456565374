import Loader from "components/utility/loader/Loader";
import "./Statistic.css";

const CountStatistic = ({ heading, icon, count, loading }) => {
  return (
    <div className="ampCommonContainer">
      <p className="statHeading">{heading}</p>
      <div className="statWithIconDiv">
        {icon}
        {!loading ? <span className="stat">{count ?? 0}</span> : <Loader size="sm" />}
      </div>
    </div>
  );
};

export default CountStatistic;
