import axios from "axios";

export default async function fetchData({ token, url, options, body = null }) {
  if (!token) throw "Access Token missing";
  var baseURL = process.env.REACT_APP_API_BASE_PATH;
  if (!baseURL) throw "API Base Path not found";

  var { data, status } = await axios({
    baseURL,
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: body,
    ...options,
  });
  console.log("Fetch result:");
  console.log(url);
  console.log(body);
  console.log(status);
  console.log(data);

  if (status !== 200) throw "Response Status does not indicate success";
  return data;
}
