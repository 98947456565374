export const domain = process.env.REACT_APP_AAD_DOMAIN;

export const b2cPolicies = {
  names: {
    signIn: "B2C_1_SIGN_IN_ONLY",
  },
  authorities: {
    signIn: {
      authority: `https://${domain}.b2clogin.com/${domain}.onmicrosoft.com/B2C_1_SIGN_IN_ONLY`,
    },
  },
  authorityDomain: `${domain}.b2clogin.com`,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile"],
};

export const apiConfig = {
  b2cScopes: [process.env.REACT_APP_AAD_API_SCOPE],
};

export const inProgressActiveStatuses = ["handleRedirect", "logout", "login", "startup"];

export const b2cErrorCodes = {
  expiredToken: "AADB2C90208",
};
