import Container from "components/Container";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import ValidatedInput from "components/ValidatedInput";
import usePbaasApp from "lib/pbaas/usePbaasApp";
import { useUrl } from "lib/site";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

function PbaasAppOverview() {
  const navigate = useNavigate();
  const appsUrl = useUrl()("PbaasApps");
  const [app, setApp, api, info] = usePbaasApp();

  useEffect(() => {
    info.serverUpdated && navigate(appsUrl);
  }, [info.serverUpdated]);

  const handleSave = async () => {
    if (await api.update()) {
      displayToast(`Pbaas app:${app.name} updated.`);
    }
  };

  const handleDelete = async () => {
    displayAlert(
      `Delete ${app.name}?`,
      `Please confirm you would like to delete the app: ${app.name}`,
      alertTypes.confirm,
      async (result) => {
        if (!result.isConfirmed) return;
        try {
          await api.delete();
          displayToast(`Pbaas app:${app.name} deleted.`);
        } catch {
          displayToast("An error occurred while deleting the app.", "error");
        }
      }
    );
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.valueAsNumber || e.target.value;
    setApp((mutableApp) => (mutableApp[name] = value));
  };

  function renderMain() {
    return (
      <div id="pbaas-app-overview" onChange={handleChange}>
        <Container heading="Overview">
          <fieldset>
            <label className="ampFormLabel" as="legend">
              Name
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="name"
              type="text"
              defaultValue={app.name}
              error={info.formErrors.name}
            />

            <label className="ampFormLabel" as="legend">
              AppId
            </label>
            <ValidatedInput className="ampFormControl" name="appId" type="text" defaultValue={app.appId} readOnly={true} />

            <label className="ampFormLabel" as="legend">
              Secret
            </label>
            <ValidatedInput className="ampFormControl" name="secret" type="text" defaultValue={app.secret} readOnly={true} />
          </fieldset>
        </Container>

        <Container heading="Validation">
          <fieldset>
            <label className="ampFormLabel" as="legend">
              PIN Min Digits
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="pinMinDigits"
              type="number"
              defaultValue={app.pinMinDigits}
              error={info.formErrors.pinMinDigits}
            />

            <label className="ampFormLabel" as="legend">
              PIN Max Digits
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="pinMaxDigits"
              type="number"
              defaultValue={app.pinMaxDigits}
              error={info.formErrors.pinMaxDigits}
            />

            <label className="ampFormLabel" as="legend">
              PIN Entry Timeout (Seconds)
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="pinEntryTimeoutSeconds"
              type="number"
              defaultValue={app.pinEntryTimeoutSeconds}
              error={info.formErrors.pinEntryTimeoutSeconds}
            />

            <label className="ampFormLabel" as="legend">
              Session Lifetime (Seconds)
            </label>
            <ValidatedInput
              className="ampFormControl"
              name="sessionLifetimeSeconds"
              type="number"
              defaultValue={app.sessionLifetimeSeconds}
              error={info.formErrors.sessionLifetimeSeconds}
            />
          </fieldset>
        </Container>
      </div>
    );
  }

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={appsUrl}>
            Cancel
          </LinkButton>
          &nbsp;&nbsp;&nbsp;
          <LinkButton type="red" onClick={handleDelete}>
            Delete
          </LinkButton>
        </PageActions>
        {renderMain()}
      </>
    );
  }

  const ready = app && !info.isBusy;

  return <>{ready ? renderContent() : <Loader centerInline />}</>;
}

export default PbaasAppOverview;
