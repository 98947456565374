import { getDefaultUrl, getNodes } from "lib/site";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AdminLogs from "screens/AdminLogs";
import AdminOverview from "screens/AdminOverview";
import AdminUserCreate from "screens/AdminUserCreate";
import AdminUserEdit from "screens/AdminUserEdit";
import AdminUsers from "screens/AdminUsers";
import AllUsers from "screens/AllUsers";
import AllUsersEdit from "screens/AllUsersEdit";
import ErrorPageNotFound from "screens/ErrorPageNotFound";
import OrganisationCreate from "screens/OrganisationCreate";
import OrganisationEdit from "screens/OrganisationEdit";
import OrganisationLogs from "screens/OrganisationLogs";
import OrganisationOverview from "screens/OrganisationOverview";
import Organisations from "screens/Organisations";
import OrganisationUserCreate from "screens/OrganisationUserCreate";
import OrganisationUserEdit from "screens/OrganisationUserEdit";
import OrganisationUsers from "screens/OrganisationUsers";
import PbaasAppAnalytics from "screens/PbaasAppAnalytics";
import PbaasAppCreate from "screens/PbaasAppCreate";
import PbaasAppLanguageCreate from "screens/PbaasAppLanguageCreate";
import PbaasAppLanguageEdit from "screens/PbaasAppLanguageEdit";
import PbaasAppLanguages from "screens/PbaasAppLanguages";
import PbaasAppLogs from "screens/PbaasAppLogs";
import PbaasAppOverview from "screens/PbaasAppOverview";
import PbaasAppPinBlock from "screens/PbaasAppPinBlock";
import PbaasApps from "screens/PbaasApps";
import PbaasAppUI from "screens/PbaasAppUI";

function RouteRender() {
  const location = useLocation();
  const nodes = getNodes();
  const defaultUrl = getDefaultUrl();

  const screens = {
    AdminLogs,
    AdminOverview,
    AdminUserCreate,
    AdminUserEdit,
    AdminUsers,
    AllUsers,
    AllUsersEdit,
    OrganisationCreate,
    OrganisationEdit,
    OrganisationLogs,
    OrganisationOverview,
    Organisations,
    OrganisationUserCreate,
    OrganisationUserEdit,
    OrganisationUsers,
    PbaasAppAnalytics,
    PbaasAppCreate,
    PbaasAppLanguageCreate,
    PbaasAppLanguageEdit,
    PbaasAppLanguages,
    PbaasAppLogs,
    PbaasAppOverview,
    PbaasAppPinBlock,
    PbaasApps,
    PbaasAppUI,
  };

  function renderScreen(screenName) {
    return React.createElement(screens[screenName]);
  }

  function renderRoute(node) {
    return <Route exact path={node.route} element={renderScreen(node.screen)} key={node.key} />;
  }

  function renderDefaultRoute() {
    const path = location.pathname;

    if (path === "/" && defaultUrl !== "/") {
      return <Navigate to={defaultUrl}></Navigate>;
    }
  }

  return (
    <>
      {renderDefaultRoute()}
      <Routes>
        {nodes.map(renderRoute)}
        <Route path={"*"} element={<ErrorPageNotFound />} />
      </Routes>
    </>
  );
}

export default RouteRender;
