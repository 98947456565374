import Container from "components/Container";
import LinkButton from "components/LinkButton";
import List from "components/List";
import PageActions from "components/PageActions";
import Loader from "components/utility/loader/Loader";
import useLanguageList from "lib/language/useLanguageList";
import { useUrl } from "lib/site";

function PbaasAppLanguages() {
    const [list, info] = useLanguageList();
    const getUrl = useUrl();
    const createUrl = getUrl("PbaasAppLanguageCreate");
    const editTemplate = getUrl("PbaasAppLanguageEdit", {locale: "LOCALE"});
    
    function createListItem(language) {
        return {
            url: editTemplate.replace("LOCALE", language.locale),
            text: language.locale 
        }
    }

    function renderList() {
        
        const items = list.map(createListItem);

        return (
            <Container heading="Languages" width={"600px"}>
                <List items={items} heading="Locales" keyField="text"/>
            </Container>
        );
    }

    function renderContent() {
        return (
            <>
                <PageActions>
                    <LinkButton to={createUrl} type="blue">Create Language</LinkButton>  
                </PageActions>
                {renderList()}
            </>
        );
    }

    const ready = list && !info.isBusy 

    return <>{ready ? renderContent() : <Loader centerInline />}</>
}

export default PbaasAppLanguages;