import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loggedOut } from "redux/user/userSlice";

export default function useSignOutHelper() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const signOutFlagKey = "AmpSignOut";
  const dispatch = useDispatch();
  
  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) window.localStorage.setItem(signOutFlagKey, false);
  }, [isAuthenticated]);

  const handleStorageChange = (e) => {
    if (e.key === signOutFlagKey && e.newValue === "true") logout(false);
  };
  
  const logout = (updateStorage = true) => {
    if (updateStorage) window.localStorage.setItem(signOutFlagKey, true);
    dispatch(loggedOut());
    instance.logoutRedirect();
  };

  return { logout };
}
