
import { usePrompt } from 'lib/misc/react-router-dom';
import { useEffect, useState } from 'react';

const message = "if you leave the page, any unsaved data will be lost. Do you wish to continue?";

export default function useUnsavedWarning(state, statePreparer) {
    const [initialState, setInitialState] = useState(null);
    
    usePrompt(message, isDirty());
    
    function getComparableState(state) {
        state = clone(state);
        state = statePreparer ? statePreparer(state) : state;
        
        // ignore empty fields for comparison...
        // this is because a value may start as undefined but then be set to 
        // null or empty string (or vice-versa), but for comparison purposes they are the same,
        // so ignore them.
        for(const f in state) {
            const v = state[f];
            if (v === null || v === "" || v === undefined) {
                delete state[f];
            } 
        }

        return state;
    }

    function isDirty() {
        const currentState = JSON.stringify(getComparableState(state)); 
        if (currentState === null || initialState === null) return false;

        /*
        if (currentState !== initialState) {
            console.log("state different");
            console.log("intiial state");
            console.log(initialState);
            console.log("current state");
            console.log(currentState);
        }
        */

        return currentState !== initialState;
    }

    function setNewInitialState(state) {
        setInitialState(JSON.stringify(getComparableState(state)))
    }

    
    useEffect(() => {
        if (state && initialState == null) {
            setInitialState(JSON.stringify(getComparableState(state)))
        }
    }
    , [state])

    function unloadCheck() {
        if (isDirty()) {
            return message;
        }
    }

    useEffect(()=> {
        window.onbeforeunload = unloadCheck;

        return () => {
            window.removeEventListener('beforeunload', unloadCheck);
        };
    }, []);

    return [setNewInitialState, isDirty]
}

function clone(o) {
    const json = JSON.stringify(o);
    return JSON.parse(json);
}