import { useMsal } from "@azure/msal-react";
import { apiConfig } from "lib/constants/aad/msalConfig";

export default function useAccessToken() {
  const { instance } = useMsal();

  const getAccessToken = async () => {
    try {
      var tokenResponse = await instance.acquireTokenSilent({ scopes: [...apiConfig.b2cScopes] });
      return tokenResponse.accessToken;
    } catch (e) {
      console.error("Could not acquire access token");
      console.error(e);
      return null;
    }
  };

  return { getAccessToken };
}
