import EditableGrid from "components/EditableGrid";

function ObjectEditor({object, errors, onChange}) {

    const handleChange = (key, _, value) => {
        onChange?.(key, value);
    }

    const items = Object.keys(object).map(field => {
        return {
            name: { value: field },
            value:{ 
                value: object[field], 
                error: errors[field]    
            }
        }
    });

    return <EditableGrid 
                items={items} 
                keyField="name" 
                denyEdit={["name"]}
                columnWidths={["100px"]} 
                onChange={handleChange}/>
}

export default ObjectEditor;