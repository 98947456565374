import "./index.css";

function CheckboxList ({items, nameField, valueField, onChange, onMouseEnter, onMouseLeave}) {

    const handleChange = e => {
        const {name, checked} = e.target;
        onChange && onChange(name, checked);
    }

    const handleMouseEnter = e => {
        const name = e.target.getAttribute("data-name");
        onMouseEnter && onMouseEnter(name);
    }
    
    const handleMouseLeave = e => {
        const name = e.target.getAttribute("data-name");
        onMouseLeave && onMouseLeave(name);
    }

    function renderItem(item) {
        const name = item[nameField];
        const value = item[valueField];

        return (
            <div 
                className="checkbox-list-item" 
                key={name}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                data-name={name}>

                <input 
                    type="checkbox" 
                    name={name} 
                    id={name}
                    checked={value} 
                    onChange={handleChange} />

                <label for={name}>{name}</label>
            </div>
        );
    }

    return (
        <div className="checkbox-list">
            {items.map(renderItem)}
        </div>
    );
}

export default CheckboxList;