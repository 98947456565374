import { getAdminOrgId } from "lib/site";
import Users from "screens/components/Users";

function AdminUsers() {
  return (
    <Users
      organisationId={getAdminOrgId()}
      createUserScreen="AdminUserCreate"
      editUserScreen="AdminUserEdit"
      displayOrganisationsFilter={false}
    />
  );
}

export default AdminUsers;
