import { displayToast } from "components/utility/alerts/Toast";
import { listPbaasApps } from "lib/fetch/requests/pbaasRequests";
import useFetch from "lib/fetch/useFetch";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function usePbaasList() {
  const { organisationId } = useParams();
  const [apps, loadApps, isLoading, loadError] = useFetch(listPbaasApps(organisationId));

  useEffect(() => {
    loadApps();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadError) displayToast(loadError.message, "error");
  }, [loadError]);

  return [apps, { isBusy: isLoading }];
}
