import SelectableElement from "../SelectableElement";

function TextDesigner({element, selectedKey, className, onSelectElement}) {

    const style = {
        color: element.fontColor,
        backgroundColor: element.backgroundColor,
        fontFamily: element.fontName,
        fontSize: element.fontSize + "px"
    }

    return (
        <>
            {element && 
            <div className={className} style={style}>
                <SelectableElement 
                    element={element} 
                    isSelected={element.key === selectedKey} 
                    onSelectElement={onSelectElement}> 
                    <div className="text-container">{element.displayText}</div>
                </SelectableElement>
            </div>
            }
        </>
    );
}

export default TextDesigner
