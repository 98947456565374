import moment from "moment";
import { useEffect, useRef } from "react";

const trackedEvents = ["mousemove", "scroll", "keydown", "onfocus"];
const storageKey = "LastInteractionTimestamp";

const useSessionTimeout = (setIsUserActive, idleDurationSeconds, idleCheckIntervalSeconds) => {
  const isActiveRef = useRef(true);

  // mark user as active on first render
  useEffect(() => {
    handleActiveUser();
  }, []);

  // initialise event listeners on window
  useEffect(() => {
    trackedEvents.forEach((e) => window.addEventListener(e, handleActiveUser));
    return () => trackedEvents.forEach((e) => window.removeEventListener(e, handleActiveUser));
  }, []);

  // timer function to monitor and call the callback to handle prompt/logout
  useEffect(() => {
    var interval = setInterval(checkLastActivity, idleCheckIntervalSeconds * 1000);

    return () => clearInterval(interval);
  }, [idleCheckIntervalSeconds]);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleActiveUser = (updateStorage = true) => {
    if (updateStorage) window.localStorage.setItem(storageKey, moment().toISOString());
    if (!isActiveRef.current) {
      isActiveRef.current = true;
      setIsUserActive(true);
    }
  };

  const handleInactiveUser = () => {
    if (isActiveRef.current) {
      isActiveRef.current = false;
      setIsUserActive(false);
    }
  };

  const checkLastActivity = () => {
    var lastInteraction = window.localStorage.getItem(storageKey);
    console.log("Last active: " + moment().diff(moment(lastInteraction), "second") + " seconds ago");

    if (moment().diff(moment(lastInteraction), "second") >= idleDurationSeconds) handleInactiveUser();
    else handleActiveUser(false);
  };

  const handleStorageChange = (e) => {
    if (e.key === storageKey) handleActiveUser();
  };
};

export default useSessionTimeout;
