import { displayToast } from "components/utility/alerts/Toast";
import produce from "immer";
import { createPbaasApp, deletePbaasApp, getPbaasApp, updatePbaasApp } from "lib/fetch/requests/pbaasRequests";
import useFetch from "lib/fetch/useFetch";
import useRequest from "lib/fetch/useRequest";
import useUnsavedWarning from "lib/portal/useUnsavedWarning";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function usePbaasApp(unsavedWarningStatePreparer) {
  const { organisationId, appId } = useParams();
  const [app, setApp] = useState(appId ? null : { name: "" });
  const [isBusy, setIsBusy] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loadedApp, loadApp, isLoading, loadError] = useFetch(getPbaasApp(organisationId, appId));
  const [updatedApp, updateApp, isUpdating, updateError] = useFetch(updatePbaasApp(organisationId));
  const [createdApp, createApp, isCreating, createError] = useFetch(createPbaasApp(organisationId));
  const [deleteApp, isDeleting] = useRequest(deletePbaasApp(organisationId, appId));
  const [setNewInitialState, isDirty] = useUnsavedWarning(app, unsavedWarningStatePreparer);
  const [serverUpdated, setServerUpdated] = useState(false);

  useEffect(() => {
    if (appId) loadApp();
  }, [appId]);

  useEffect(() => {
    if (loadedApp) {
      setApp(loadedApp);
    }
  }, [loadedApp]);

  useEffect(() => {
    if (updatedApp) {
      setApp(updatedApp);
      setNewInitialState(updatedApp);
    }
  }, [updatedApp]);

  useEffect(() => {
    if (createdApp) {
      setApp(createdApp);
      setNewInitialState(createdApp);
    }
  }, [createdApp]);

  useEffect(() => {
    handleError(loadError);
    handleError(createError);
    handleError(updateError);
  }, [loadError, createError, updateError]);

  useEffect(() => {
    setIsBusy(isLoading || isCreating || isUpdating || isDeleting);
  }, [isLoading, isCreating, isUpdating, isDeleting]);

  function handleError(err) {
    if (!err) return;
    if (err?.response?.status === 409) {
      displayToast("App with same name already exists", "error");
    } else {
      displayToast(err.message, "error");
    }
  }

  async function updateAppWrapper() {
    if (!validate()) return false;
    const result = await updateApp(app);
    if (result) {
      setNewInitialState(app);
      setServerUpdated(true);
    }
    return result;
  }

  async function deleteAppWrapper() {
    const result = await deleteApp(appId);
    setNewInitialState(app);
    setServerUpdated(true);
    return result;
  }

  async function createAppWrapper() {
    const errors = {};
    if (!app.name.trim()) errors.name = "App name is required";
    else if (app.name.length > 50) errors.name = "App name cannot exceed 50 characters";

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    }
    const result = await createApp(app);
    if (result) {
      setNewInitialState(app);
      setServerUpdated(true);
    }
    return result;
  }

  function setAppMutable(action) {
    setApp((prevState) => {
      const nextState = produce(prevState, (draft) => {
        action(draft);
      });

      return nextState;
    });
  }

  function validate() {
    const errors = {};
    if (!app.name.trim()) errors.name = "App name is required";
    if (app.name.length > 50) errors.name = "App name cannot exceed 50 characters";

    if (app.pinMinDigits.trim && !app.pinMinDigits.trim()) errors.pinMinDigits = "PIN Min Digits is required";
    else if (!Number.isInteger(parseInt(app.pinMinDigits))) errors.pinMinDigits = "PIN Min Digits must be an integer";
    else if (app.pinMinDigits < 4) errors.pinMinDigits = "PIN Min Digits cannot be less than 4";
    else if (app.pinMinDigits > 12) errors.pinMinDigits = "PIN Min Digits cannot be exceed 12";

    if (app.pinMaxDigits.trim && !app.pinMaxDigits.trim()) errors.pinMaxDigits = "PIN Max Digits is required";
    else if (!Number.isInteger(parseInt(app.pinMaxDigits))) errors.pinMaxDigits = "PIN Max Digits must be an integer";
    else if (app.pinMaxDigits < 4) errors.pinMaxDigits = "PIN Max Digits cannot be less than 4";
    else if (app.pinMaxDigits > 12) errors.pinMaxDigits = "PIN Max Digits cannot be exceed 12";
    else if (app.pinMaxDigits < app.pinMinDigits) errors.pinMaxDigits = "PIN Max Digits cannot be less than PIN Mun Digits";

    if (app.pinEntryTimeoutSeconds.trim && !app.pinEntryTimeoutSeconds.trim())
      errors.pinEntryTimeoutSeconds = "Pin Entry Timeout is required";
    else if (!Number.isInteger(parseInt(app.pinEntryTimeoutSeconds)))
      errors.pinEntryTimeoutSeconds = "Pin Entry Timeout must be an integer";
    else if (app.pinEntryTimeoutSeconds < 30) errors.pinEntryTimeoutSeconds = "Pin Entry Timeout cannot be less than 30";
    else if (app.pinEntryTimeoutSeconds > 120) errors.pinEntryTimeoutSeconds = "Pin Entry Timeout cannot be exceed 120";

    if (app.sessionLifetimeSeconds.trim && !app.sessionLifetimeSeconds.trim())
      errors.sessionLifetimeSeconds = "Session Lifetime is required";
    else if (!Number.isInteger(parseInt(app.sessionLifetimeSeconds)))
      errors.sessionLifetimeSeconds = "Session Lifetime must be an integer";
    else if (app.sessionLifetimeSeconds < 60) errors.sessionLifetimeSeconds = "Session Lifetime cannot be less than 60";
    else if (app.sessionLifetimeSeconds > 300) errors.sessionLifetimeSeconds = "Session Lifetime cannot be exceed 300";
    else if (app.sessionLifetimeSeconds < app.pinEntryTimeoutSeconds)
      errors.sessionLifetimeSeconds = "Session Lifetime cannot be less than PIN Entry Timeout.";

    if (!app.pinBlockFormat) errors.pinBlockFormat = "PIN Block Format is required.";
    if (!app.pinBlockType) errors.pinBlockType = "PIN Block Type is required.";

    if (!app.pinBlockKeyUnderLmk.trim()) errors.pinBlockKeyUnderLmk = "PIN Block Encryption Key is required.";
    else if (app.pinBlockKeyUnderLmk.length > 255)
      errors.pinBlockKeyUnderLmk = "PIN Block Encryption Key cannot exceed 255 characters.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  return [
    app,
    setAppMutable,
    {
      create: createAppWrapper,
      delete: deleteAppWrapper,
      update: updateAppWrapper,
    },
    {
      formErrors,
      isBusy,
      isDirty,
      serverUpdated,
    },
  ];
}
