import { httpMethods } from "./util";

export function getOrganisations() {
  return {
    url: `Organisation`,
  };
}

export function getOrganisationById(organisationId) {
  return {
    url: `Organisation/${organisationId}`,
  };
}

export function updateOrganisationById(organisationId) {
  return {
    url: `Organisation/${organisationId}`,
    options: { method: httpMethods.put },
  };
}

export function createOrganisation() {
  return {
    url: `Organisation`,
    options: { method: httpMethods.post },
  };
}

export function deleteOrganisationById(organisationId) {
  return {
    url: `Organisation/${organisationId}`,
    options: { method: httpMethods.delete },
  };
}
