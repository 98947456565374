import Container from "components/Container";
import LinkButton from "components/LinkButton";
import PageActions from "components/PageActions";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import useLanguageTranslate from "lib/language/useLanguageTranslate";
import usePbaasApp from "lib/pbaas/usePbaasApp";
import { useUrl } from "lib/site";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import PbaasDialogDesigner from "./PbaasDialogDesigner";
import PbaasPinPadDesigner from "./PbaasPinPadDesigner";
import PbaasPropertyGrid from "./PbaasPropertyGrid";

function PbaasAppUI() {
  const navigate = useNavigate();
  const appsUrl = useUrl()("PbaasApps");

  const [app, setApp, api, info] = usePbaasApp(prepareForTracking);
  const [currentLocale, setCurrentLocale, locales, getText] = useLanguageTranslate();
  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    info.serverUpdated && navigate(appsUrl);
  }, [info.serverUpdated]);

  useEffect(() => {
    if (app) {
      setApp((mutableApp) => {
        nestedEach((obj, i) => {
          obj.key = i;
        }, mutableApp);
      });
    }
  }, [app, setApp]);

  useEffect(() => {
    if (currentLocale) {
      setApp((mutableApp) => {
        nestedEach((obj, i) => {
          if (obj.text) obj.displayText = getText(obj.text);
        }, mutableApp);
      });
    }
  }, [app, currentLocale, setApp, getText]);

  const handleSelectElement = (key) => {
    setSelectedKey(key);
  };

  const handlePropertyChange = (name, value) => {
    setApp((mutableApp) => {
      const selected = getSelectedElement(mutableApp);
      selected[name] = value;
    });
  };

  const handleLocaleChange = (event) => {
    setCurrentLocale(event.target.value);
  };

  const handleSave = async () => {
    if (await api.update()) {
      displayToast(`Pbaas app:${app.name} updated.`);
    }
  };

  function prepareForTracking(app) {
    if (!app) return app;

    const propsToRemove = ["key", "displayText"];
    const c = clone(app);

    nestedEach((obj) => {
      for (const prop of propsToRemove) {
        delete obj[prop];
      }
    }, c);

    return c;
  }

  function getSelectedElement(elements) {
    if (!app || !selectedKey) return null;
    return getElement((e) => e.key === selectedKey, elements || app);
  }

  function renderContent() {
    return (
      <>
        <PageActions>
          <LinkButton type="blue" onClick={handleSave} disabled={!info.isDirty()}>
            Save
          </LinkButton>
          <LinkButton type="grey" to={appsUrl}>
            Cancel
          </LinkButton>
        </PageActions>

        <div className="pbaas-app-ui">
          <div className="designers">
            <Container heading="PIN Pad" align="center">
              <PbaasPinPadDesigner pinPad={app.ui.pinPad} selectedKey={selectedKey} onSelectElement={handleSelectElement} />
            </Container>
            <Container heading="Cancel Dialog" align="center">
              <PbaasDialogDesigner dialog={app.ui.cancelDialog} selectedKey={selectedKey} onSelectElement={handleSelectElement} />
            </Container>
          </div>
          <div className="controls">
            <div className="language-container">
              <label>Preview Language</label>
              <select className="ampFormSelect" name="pinBlockType" value={currentLocale} onChange={handleLocaleChange}>
                {locales.map((locale) => (
                  <option value={locale}>{locale}</option>
                ))}
              </select>
            </div>
            <div className="property-grid-container">
              <PbaasPropertyGrid element={getSelectedElement()} onChange={handlePropertyChange} />
            </div>
          </div>
        </div>
      </>
    );
  }

  const ready = app && !info.isBusy;

  return <>{ready ? renderContent() : <Loader centerInline />}</>;
}

export default PbaasAppUI;

function getElement(predicate, elements) {
  const match = getElements(predicate, elements);

  if (match.length === 0) return null;

  if (match.length !== 1) {
    throw new Error(`Expected to select a single element but ${match.length} were selected.`);
  }

  return match[0];
}

function getElements(predicate, elements) {
  const output = [];
  nestedEach((o) => {
    if (predicate(o)) output.push(o);
  }, elements);
  return output;
}

function nestedEach(action, objects, index) {
  if (index === undefined) index = 0;
  if (!Array.isArray(objects)) {
    objects = [objects];
  }

  for (var obj of objects) {
    if (obj) {
      action(obj, index++);
      const childObjects = [];
      const props = Object.keys(obj);
      for (var prop of props) {
        if (typeof obj[prop] === "object" && obj[prop] !== null) {
          childObjects.push(obj[prop]);
        }
      }
      index = nestedEach(action, childObjects, index);
    }
  }
  return index;
}

function clone(o) {
  const json = JSON.stringify(o);
  return JSON.parse(json);
}
