import Loader from "components/utility/loader/Loader";
import { useUrl } from "lib/site";
import React from "react";
import { Link } from "react-router-dom";

const UsersList = ({ users, editUserScreen, isFetching }) => {
  const getUrl = useUrl();

  for (var user of users || []) {
    user.editUrl = getUrl(editUserScreen, { organisationId: user.organisationId, userId: user.userId });
  }

  return (
    <div id="usersListDiv">
      <table className="ampTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Permissions</th>
          </tr>
        </thead>
        <tbody>
          {!isFetching &&
            users?.map((user) => (
              <tr key={user.userId}>
                <td>
                  <Link to={user.editUrl}>{user.displayName}</Link>
                </td>
                <td>{user.email}</td>
                <td>{user && user.permissions && user.permissions.map((r) => r.name).join(", ")}</td>
              </tr>
            ))}
          {!isFetching && users && !users.length && (
            <tr>
              <td className="text-start" colSpan={3}>
                <span>No users found.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isFetching && <Loader centerInline={true} size={"sm"} />}
    </div>
  );
};

export default UsersList;
