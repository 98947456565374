import "./index.css";
 
function EditableGrid({items, keyField, denyEdit, columnWidths, onChange}) {

    const handleChange = e => {
        const {name, value } = e.target;
        const dataKey = e.target.dataset.datakey;
        onChange(dataKey, name, value)
    }

    function renderField(item, field, index) {
        const editable = !denyEdit.some(d => d === field);
        
        const style = {
            width: columnWidths[index]
        }

        return (
            <td key={(item[keyField].value) + field} style={style}>
            {editable ? 
                <input name={field} defaultValue={item[field].value} data-datakey={item[keyField].value} onChange={handleChange} autoComplete="off" />
                :
                <div className="value">{item[field].value}</div>
            }
                <div className="error-container">
                    {item[field].error}
                </div>
            </td>
        )
    }

    function renderItem(item) {
        return (
            <tr key={item[keyField].value}>
                {Object.keys(item).map((field, i) => renderField(item, field, i))}
            </tr>
        )
    }; 

    return (
        items && 
        <table className="editable-grid">
            <tbody>
                {items.map(renderItem)}
            </tbody>
        </table>
    );
}

export default EditableGrid